import { Box, Grid, Link, Typography } from "@mui/material";

const styleImg = {
  height: "auto",
  filter: `grayscale(100%)`,
  opacity: 0.5,
  transition: `opacity 0.5s ease`,
  "&:hover": {
    opacity: 1,
    filter: "none",
  },
};
const ImagesShow = (props) => {
  return (
    <>
      <Typography variant="h5" component="h2" sx={{ mb: 4, mt: 7, textAlign: 'center', fontWeight: 1000, opacity: 0.5 }}>
        {props.text}
      </Typography>
      <Grid
        container
        spacing={3}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        {props.images &&
          props.images.map((img, idx) => (
            <Grid key={idx} item xs={6} sm={3} textAlign='center'>
              <Link href={img.link}  target="_blank" rel="noopener noreferrer">
                <Box
                  component="img"
                  alt={img.name}
                  src={img.src}
                  className="imgTrans"
                  sx={{
                    maxWidth: props.width,
                    ...styleImg,
                  }}
                />
              </Link>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default ImagesShow;
