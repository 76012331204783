const cyr = {
    home: 'Почетна',
    profile: 'Профил',
    education: 'Едукација',
    news: 'Вести',
    tests: 'Тестови',
    logout: 'Одјави се',
    login: 'Пријави се',
    register: 'Региструј се',
    email: 'Имејл',
    resetFilter: 'Уклони филтер',
    password: 'Лозинка',
    rememberMe: 'Запамти ме',
    cancel: 'Откажи',
    submit: 'Потврди',
    submitLogin: 'Пријави се',
    submitRegister: 'Региструј се',
    submitForgot: 'Потврди',
    submitResetPassword: 'Потврди',
    submitProfile: 'Потврди',
    submitChangePassword: 'Потврди',
    submitDeleteAccount: 'Потврди',
    required: 'Обавезно поље',
    invalidEmail: 'Неисправан имејл',
    tooCommon: 'Ова лозинка је уобичајена',
    alreadyExist: 'Кориснички налог са овом имејл адресом већ постоји',
    passwordSimilarEmail: 'Лозинка не сме бити слична имејлу',
    checkData: 'Проверите да ли сте добро унели податке',
    min8: 'Лозинка мора садржати најмање 8 карактера',
    max40: 'Лозинка не сме прећи више од 40 карактера',
    noActiveAccount: 'Не постоји налог са овим креденцијалима',
    forgotPassword: 'Заборављена лозинка',
    dontHaveAccount: 'Ако још немате налог ',
    registerPage: 'идите на страницу за регистрацију.',
    succesForgot: 'Послали смо Вам имејл са линком за промену лозинке! Ако не примите имејл у наредних пар минута, проверите у порукама промоције или спам фолдеру.',
    goToStart: 'Врати се на почетну страну',
    name: 'Име и презиме',
    rePassword: 'Понови лозинку',
    language: 'Језик',
    sr: 'Српски',
    en: 'Енглески',
    byRegister: 'Регистрацијом прихватам ',
    termsAndCondition: 'Услове и Правила коришћења.',
    termsAndConditionModal: 'Услови и Правила коришћења',
    haveAccount: 'Ако имате налог ',
    loginPage: 'идите на страницу за пријављивање.',
    currentPassword: 'Тренутна лозинка',
    newPassword: 'Нова лозинка',
    reNewPassword: 'Понови нову лозинку',
    matchPassword: 'Лозинке се не подударају',
    successChangePass: 'Успешно сте променили лозинку.',
    createAvatar: 'Додај слику',
    male: 'Мушки',
    female: 'Женски',
    other: 'Друго',
    dateOfBirth: 'Датум рођења',
    gender: 'Пол',
    city: 'Град',
    significantTrials: 'Значајна суђења',
    engagements: 'Ангажовање',
    league: 'Лига',
    startDate: 'Почетак ангажовања',
    endDate: 'Крај ангажовања',
    addEngagement: 'Додај ангажовање',
    succesProfileCreate: 'Успешно сте креирали профил!',
    succesProfileEdit: 'Успешно сте изменили профил!',
    requiredEngagement: 'Обавезно додати ангажовање',
    hello: 'Здраво ',
    currentLeague: 'Тренутно ангажовање',
    startTest: 'Започни писмени тест',
    startVideoTest: 'Започни видео тест',
    editProfile: 'Уреди профил',
    changePassword: 'Промени лозинку',
    deleteAccount: 'Обриши налог',
    invalidCurrentPassword: 'Погрешна тренутна лозинка',
    successDeleteAccount: 'Успешно сте обрисали налог.',
    noTest: 'Нажалост, тренутно немате ни један тест.',
    notFound: 'Тражена страница није пронађена.',
    alreadyExistEng: 'Изабрано ангажовање се налази у унетим ангажовањима.',
    noMoreEngWomen: 'Не можете бити тренутно ангажовани у више од две лиге',
    noMoreEngMan: 'Не можете бити тренутно ангажовани у више од једне лиге',
    checkDate: 'Проверите унете податке.',
    smallDate: 'Крај ангажовања не може бити мањи од почетка ангажовања',
    serverError: 'Серверска грешка',
    emptyPosts: 'Нема едукативних чланака',
    buttonFile: 'Преузми фајл',
    appTitle1: 'Фудбалски Савез Србије',
    appTitle2: 'Портал за судије',
    match: 'Утакмица',
    referee: 'Судија',
    observer: 'Посматрач',
    assist: 'Помоћне судије',
    label: 'Типови',
    submitFilter: 'Примени филтер',
    generalSponsor: 'Генерални спонзор',
    sponsor: 'Спонзори',
    mediaSponsor: 'Медијски спонзори',
    addBusyDate: 'Недоступан',
    current: 'Тренутно',
    calendarUnavailable: 'Календар недоступности',
    date: 'Датум',
    changeUnavailableDate: 'Промени датум',
    deleteUnavailableDate: 'Обриши датум',
    errorExistingDate: 'Датум већ постоји',
    labelUser: 'Лабела',

}

export default cyr;
