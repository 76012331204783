import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material"; // Import icons
import moment from 'moment';

const BusyDateItem = ({ text, onEditPress, onDeletePress }) => {
  return (
    <ListItem sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
      <ListItemText primary={moment(text).format("DD. MM. YYYY.")} />
      <ListItemIcon sx={{ justifyContent: "flex-end" }}>
        <EditIcon onClick={onEditPress} sx={{ cursor: "pointer" }} />
      </ListItemIcon>
      <ListItemIcon sx={{ justifyContent: "flex-end" }}>
        <DeleteIcon onClick={onDeletePress} sx={{ cursor: "pointer" }} />
      </ListItemIcon>
    </ListItem>
  );
};

export default BusyDateItem;
