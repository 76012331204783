import React from "react";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import CustomTextField from "../../infrastructure/components/CustomTextField";
import { LocalizationContext } from "../../context/LocalizationContext";

import client from "../../apis/client";
import Spacer from "../../infrastructure/Spacer";
import { colors } from '../../infrastructure/components/resources/colors';
import moment from 'moment';

const EditBusyDate = ({onClose, data, profileData}) => {
  const { t } = React.useContext(LocalizationContext);
  const [error, setError] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);

  const initialValues = {
    date: moment(data?.date).format(
        "YYYY-MM-DD"
      )
  }

  const changePassword = async (values) => {
    try {
      setDisabled(true);
        const val = {
            date: values.date,
            profile: profileData?.id
        }
        const res = await client.put(`/unavailable_dates/${data.id}/`, val);

        if (res.status === 200) {
          onClose()
        }
        setDisabled(false);
    } catch (error) {
      const values = Object.values(error.response.data);
      const keys = Object.keys(error.response.data);
     
      if (keys !== undefined && values !== undefined) {
        if (keys.length !== 0 && values.length !== 0) {
          if (
            values[0][0].includes('The fields profile, date must make ') &&
            keys[0] === "non_field_errors"
          ) {
            setError(t("errorExistingDate"));
            return;
          } else {
            setError(t("checkData"));
            return;
          }
        }
      }
      setDisabled(false);
    }
  };

  const validationSchema = yup.object({
    date: yup.string().required(t("required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: changePassword,
  });

  return (
    <Container component="main" maxWidth="sm">
      <div xs={12} sm={6} lg={8}>
        <form onSubmit={formik.handleSubmit} className="mt-3 m-2">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="date"
                label={t("date")}
                name="date"
                variant="outlined"
                type="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                error={
                  formik.touched.date &&
                  Boolean(formik.errors.date)
                }
                helperText={
                  formik.touched.date && formik.errors.date
                }
              />
            </Grid>

            {error !== "" && (
              <Grid item xs={12}>
                <p style={{ color: "red" }}>{error}</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <Spacer height="1rem" />
              <Button
                variant="contained"
                disabled={disabled}
                fullWidth
                type="submit"
                sx={{ backgroundColor: colors.red }}
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default EditBusyDate;
