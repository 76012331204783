import React from "react";

import { Grid, Typography } from "@mui/material";
import { colors } from "./resources/colors";

const EmptyComponenet = (props) => {
  return (
    <Grid container display={"flex"} justifyContent={"center"}>
      <Typography sx={{ color: colors.red, opacity: 0.7, fontSize: "1.5rem" }}>
        {props.text}
      </Typography>
    </Grid>
  );
};

export default EmptyComponenet;
