import React from "react";
import { useParams } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import CustomTextField from "../../infrastructure/components/CustomTextField";
import { LocalizationContext } from "../../context/LocalizationContext";

import history from "../../infrastructure/history";
import client from "../../apis/client";
import Spacer from "../../infrastructure/Spacer";
import { colors } from '../../infrastructure/components/resources/colors';

const initialValues = {
  new_password: "",
  re_new_password: "",
};

const ResetPassword = () => {
  const { t } = React.useContext(LocalizationContext);

  const { uid, token } = useParams();

  const resetPassword = async (values) => {
    try {
      const new_password = values.new_password;
      const re_new_password = values.re_new_password;

      if (new_password === re_new_password) {
        const res = await client.post("/auth/users/reset_password_confirm/", {
          uid: uid,
          token: token,
          new_password: new_password,
        });

        if (res.status === 204) {
          alert(t("successChangePass"));
        }
        history.push(`/`);
        history.go();
      }
    } catch (err) {
      if (err.response) {
        alert(JSON.stringify(err.response.data));
      }
    }
  };

  const validationSchema = yup.object({
    new_password: yup
      .string()
      .required(t("required"))
      .min(8, t("min8"))
      .max(40, t("max40")),
    re_new_password: yup
      .string()
      .required(t("required"))
      .oneOf([yup.ref("new_password"), null], t("matchPassword")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: resetPassword,
  });

  return (
    <>
      <Spacer height={"2rem"} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "1rem",
          marginRight: "4rem",
        }}
      >
        <a href="/" className="link_text">
          {t("goToStart")}
        </a>
      </div>
      <Container component="main" maxWidth="sm">
        <div xs={12} sm={6} lg={8}>
          <form onSubmit={formik.handleSubmit} className="mt-3 m-2">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  id="new_password"
                  label={t("newPassword")}
                  name="new_password"
                  variant="outlined"
                  type="password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.new_password &&
                    Boolean(formik.errors.new_password)
                  }
                  helperText={
                    formik.touched.new_password && formik.errors.new_password
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  id="re_new_password"
                  label={t("reNewPassword")}
                  name="re_new_password"
                  variant="outlined"
                  type="password"
                  value={formik.values.re_new_password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.re_new_password &&
                    Boolean(formik.errors.re_new_password)
                  }
                  helperText={
                    formik.touched.re_new_password &&
                    formik.errors.re_new_password
                  }
                />
              </Grid>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                style={{ backgroundColor: colors.red }}
              >
                {t("submitResetPassword")}
              </Button>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};

export default ResetPassword;
