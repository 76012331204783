import React from 'react'

import { Context as AuthContext } from '../context/AuthContext'

const AppStore = (props) => {
    const { tryLocalSignin } = React.useContext(AuthContext);

    React.useEffect(() => {
        tryLocalSignin()
    }, []);

    return (
        <>
            {props.children}
        </>
    )
}

export default AppStore
