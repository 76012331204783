import React from "react";
import { Card, CardContent, Typography, CardActions } from "@mui/material";
import { LocalizationContext } from "../../context/LocalizationContext";
import CalendarPage from "../../pages/CalendarPage";
import { colors } from './resources/colors';

const styles = {
  height: { md: "40vh", sm: "60vh" },
  borderBottom: `1px solid ${colors.red}`,
  boxShadow: `0 0 10px rgba(${colors.redRgba}, 0.6)`
}

const CalendarCard = ({
  busyDates,
  handleClose,
  profileData,
  onEditDate,
  onDeleteDate,
  setData,
}) => {
  const { t } = React.useContext(LocalizationContext);

  return (
    <Card sx={styles}>
      <CardActions>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("calendarUnavailable")}
        </Typography>
      </CardActions>
      <CardContent>
        <CalendarPage
          busyDates={busyDates}
          handleClose={handleClose}
          profileData={profileData}
          onDeleteDate={onDeleteDate}
          onEditDate={onEditDate}
          setData={setData}
        />
      </CardContent>
    </Card>
  );
};

export default CalendarCard;
