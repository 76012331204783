import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { LocalizationContext } from "../../context/LocalizationContext";

import { colors } from '../../infrastructure/components/resources/colors';

const ConfirmForm = ({onSubmit, onCancel, disabled}) => {
  const { t } = React.useContext(LocalizationContext);


  return (
    <Container component="main" maxWidth="sm">
      <div xs={12} sm={6} lg={8}>
          <Grid container direction='row' spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                disabled={disabled}
                fullWidth
                onClick={onSubmit}
                sx={{ backgroundColor: colors.red }}
              >
                {t("submit")}
              </Button>
              </Grid>
              <Grid item>
              <Button
                variant="contained"
                fullWidth
                onClick={onCancel}
                sx={{ backgroundColor: 'white', border: `1px solid ${colors.red}`, color: colors.red }}
              >
                {t("cancel")}
              </Button>
              </Grid>
          </Grid>
      </div>
    </Container>
  );
};

export default ConfirmForm;
