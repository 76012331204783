import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Spacer from "../../Spacer";
import "./MultiChoiceAnswer.css";
import { colors } from "../resources/colors";
import Timer from "../Timer";
import { Button, FormLabel } from "@mui/material";

const time = {
  timeMinutes: 1,
  timeSeconds: 30,
};

const MultiChoiceAnswer = (props) => {
  const {
    answersArray,
    questionText,
    questionId,
    setSingleChoiceArray,
    singleChoiceArray,
    onNextClick,
  } = props;

  const [data, setData] = useState(
    answersArray.map((item) => ({
      answer_id: item.id,
      answer_text: item.choice,
      checked: false,
    }))
  );

  const [minutes, setMinutes] = useState(time.timeMinutes);
  const [seconds, setSeconds] = useState(time.timeSeconds);

  const { onInputChange } = props;

  useEffect(() => {
    onInputChange(singleChoiceArray);
  }, [singleChoiceArray, onInputChange]);

  useEffect(() => {
    setData(
      answersArray.map((item) => ({
        answer_id: item.id,
        answer_text: item.choice,
        checked: false,
      }))
    );

    resetTimer();
  }, [answersArray, questionText]);

  const resetTimer = () => {
    setMinutes(time.timeMinutes);
    setSeconds(time.timeSeconds);
    localStorage.removeItem("minutes");
    localStorage.removeItem("seconds");
  };

  const selectItem = (key) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.answer_id === key
          ? { ...item, checked: !item.checked }
          : item
      )
    );
  };

  const handleNextClick = () => {
    const checkedAnswers = data
      .filter((item) => item.checked)
      .map((item) => item.answer_id);

    setSingleChoiceArray([
      ...singleChoiceArray,
      {
        choice_answer: { choices: checkedAnswers.join("$") },
        question: questionId,
      },
    ]);

    setData((prevData) =>
      prevData.map((item) => ({
        ...item,
        checked: false,
      }))
    );

    resetTimer();

    onNextClick();
  };

  return (
    <>
      <Spacer height={"0.5rem"} />
      <Timer
        key={questionId}
        initialMinute={minutes}
        initialSeconds={seconds}
        onTimerEnd={handleNextClick}
      />
      <FormGroup>
        <FormLabel id="radio-buttons-group" focused={false}>
          {questionText}
        </FormLabel>
        {data.map((a) => {
          return (
            <div key={a.answer_id}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      "&.Mui-checked": {
                        color: colors.red,
                      },
                    }}
                    checked={a.checked}
                    onChange={() => selectItem(a.answer_id)}
                  />
                }
                label={a.answer_text}
              />
            </div>
          );
        })}
      </FormGroup>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={handleNextClick}
          variant='contained'
          sx={{
            color: colors.white,
            background: colors.red,
            width: "fit-content",
          }}
        >
          Следеће
        </Button>
      </div>
    </>
  );
};

export default MultiChoiceAnswer;
