import React, { useState, useEffect } from "react";

import { Typography } from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Spacer from "../../Spacer";

const time = {
  timeForQuestion: 20,
};

const SingleChoiceAnswer = (props) => {
  const [singleChoiceQuestions, setSingleChoiceQuestions] = React.useState([]);
  const [currentChoice, setCurrentChoice] = useState(0);
  const [seconds, setSeconds] = useState(time.timeForQuestion);

  useEffect(() => {
    if (
      singleChoiceQuestions.choice_answer !== undefined &&
      Object.keys(singleChoiceQuestions.choice_answer).length === 3
    ) {
      updateCurrentChoice();
    }
    if (currentChoice === 1) {
      if (singleChoiceQuestions.length === 0) {
        props.setSingleChoiceArray([
          ...props.singleChoiceArray,
          {
            choice_answer: {},
            question: props.questionId,
          },
        ]);
      } else {
        props.setSingleChoiceArray([
          ...props.singleChoiceArray,
          singleChoiceQuestions,
        ]);
      }
    }
  }, [singleChoiceQuestions, currentChoice]);

  const updateCurrentChoice = () => {
    if (currentChoice === 0) {
      setCurrentChoice(currentChoice + 1);
    }
    if (currentChoice === 1) {
      if (singleChoiceQuestions.length === 0) {
        props.setSingleChoiceArray([
          ...props.singleChoiceArray,
          {
            choice_answer: {},
            question: props.questionId,
          },
        ]);
      } else {
        props.setSingleChoiceArray([
          ...props.singleChoiceArray,
          singleChoiceQuestions,
        ]);
      }
    }
  };

  useEffect(() => {
    let myInterval;
    myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        localStorage.setItem("videoSeconds", seconds);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
        localStorage.removeItem("videoSeconds");
        updateCurrentChoice();
        updateCurrentChoice();
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  return (
    <>
      <Spacer height={"0.5rem"} />
      {seconds === 0 ? (
        <Typography
          textAlign={'end'}
          variant="h6"
        >{`00 секунди`}</Typography>
      ) : (
        <Typography textAlign={'end'} variant="h6">
          {seconds < 10 ? `0${seconds} секунди` : `${seconds} секунди`}
        </Typography>
      )}

      {props.answersArray.map((qst, index) => {
        return (
          <FormControl key={index} sx={{display: 'flex', flexDirection: 'column'}}>
            <Spacer height={"1rem"} />
            <FormLabel id="radio-buttons-group" focused={false}>
              {qst.text}
            </FormLabel>
            <RadioGroup aria-labelledby="radio-buttons-group" name={qst.text}>
              {qst.choices.map((a, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={a[0]}
                    control={<Radio />}
                    label={a[1]}
                    onChange={() => {
                      setSingleChoiceQuestions({
                        choice_answer: {
                          ...singleChoiceQuestions.choice_answer,
                          [qst.choice]: a[0],
                        },
                        question: props.questionId,
                      });
                    }}
                  />
                );
              })}
            </RadioGroup>
            <Spacer height={"0.5rem"} />
          </FormControl>
        );
      })}
    </>
  );
};
export default SingleChoiceAnswer;
