import React, { useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";

import { Menu } from "@mui/icons-material";

import { LocalizationContext } from "../../context/LocalizationContext";
import { Context as AuthContext } from "../../context/AuthContext";
import { logo } from "../components/resources/images";

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = React.useContext(LocalizationContext);
  const { logout } = React.useContext(AuthContext);

  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link href="/" color="inherit" underline="none" margin={1}>
                {t("home")}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link
                href="/edukacija"
                color="inherit"
                underline="none"
                margin={1}
              >
                {t("education")}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link
                onClick={() => logout()}
                color="inhertit"
                underline="none"
                margin={1}
              >
                {t("logout")}
              </Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component={"img"}
        src={logo.src}
        href="/"
        maxWidth={"70px"}
        sx={{ padding: 1, marginRight: "auto" }}
      />
      <div style={{ display: "flex", alignContent: "flex-end" }}>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <Menu />
        </IconButton>
      </div>
    </>
  );
}
export default DrawerComponent;
