import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { LocalizationContext } from "../../context/LocalizationContext";
import { logo } from "../components/resources/images";
import Spacer from "../Spacer";
import { Context as AuthContext } from "../../context/AuthContext";

import LanguageDropdown from "../../infrastructure/components/ui-components/LanguageDropdown";
import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DrawerComponent from "./DrawerComponent";
import history from "../history";
import { useLocation } from "react-router-dom";
import { colors } from "../components/resources/colors";

const MainNavigator = (props) => {
  const { t } = React.useContext(LocalizationContext);
  const { logout } = React.useContext(AuthContext);
  const location = useLocation();
  const [activeUrl, setActiveUrl] = React.useState(location.pathname);

  React.useEffect(() => {
    setActiveUrl(location.pathname);
  }, [location]);

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const navigateToHome = () => {
    history.push("/");
    history.go();
  };

  return (
    <Box maxWidth={"100vw"}>
      <AppBar sx={{ boxShadow: "none", background: "white" }}>
        <LanguageDropdown />
        <Toolbar>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <Box
                  component={"img"}
                  src={logo.src}
                  onClick={navigateToHome}
                  maxWidth={"70px"}
                  sx={{ padding: 1 }}
                />
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="div"
                color={"grey"}
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <Link href="/" color={activeUrl === '/' ? colors.red : 'grey'} underline="none" margin={1}>
                  {t("home")}
                </Link>
                <Link
                  href="/edukacija"
                  color={activeUrl === '/edukacija' ? colors.red : 'grey'}
                  underline="none"
                  margin={1}
                >
                  {t("education")}
                </Link>
              </Typography>
              <Box
                sx={{
                  flexGrow: 0,
                  marginLeft: "auto",
                  "&:hover": {
                    cursor: "pointer",
                    color: "#234668",
                  },
                }}
              >
                <Link onClick={() => logout()} color="grey" underline="none">
                  {t("logout")}
                </Link>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Spacer height={"7rem"} />
    </Box>
  );
};

export default MainNavigator;
