import React from "react";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import CustomTextField from "../../infrastructure/components/CustomTextField";
import { LocalizationContext } from "../../context/LocalizationContext";

import client from "../../apis/client";
import Spacer from "../../infrastructure/Spacer";
import { colors } from "../../infrastructure/components/resources/colors";
import { MenuItem, TextField } from "@mui/material";
import moment from "moment";

const UnavailableForm = ({ userLabels, labels, setUnavailableUsers, setChoosenLabel }) => {
  const { t } = React.useContext(LocalizationContext);
  const [error, setError] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);

  const usersLabels = userLabels.map((l)=>l.id)

  const noLabels = ['#admin', '#test', '#gost', '#reporter', '#superadmin', '#supervizor']

  const dropDownLabels = labels.filter((label) => {
    if(usersLabels.includes(label.id)) {
      return label
    }}
    );

  const filteredLabels = dropDownLabels.filter(label => {
    if(!noLabels.includes(label.name)) {
      return label
    }}
    );


  const initialValues = {
    date: moment(new Date()).format("YYYY-MM-DD"),
    label: "",
  };

  const findUnavailable = async (values) => {
    try {
      setDisabled(true);

      setError("");
      const res = await client.get(`/unavailable_profiles/`, {
        params: values,
      });

      if (res?.status === 200) {
        setUnavailableUsers(res.data);
        setChoosenLabel(values.label)
      }

      setDisabled(false);
    } catch (error) {
      const values = Object.values(error.response.data);
      const keys = Object.keys(error.response.data);

      if (keys !== undefined && values !== undefined) {
        if (keys.length !== 0 && values.length !== 0) {
          if (
            values[0][0] === "The password is too similar to the email." &&
            keys[0] === "new_password"
          ) {
            setError(t("passwordSimilarEmail"));
            return;
          } else {
            setError(t("checkData"));
            return;
          }
        }
      }
      setDisabled(false);
    }
  };

  const validationSchema = yup.object({
    date: yup.string().required(t("required")),
    label: yup.string().required(t("required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: findUnavailable,
  });

  return (
    <Container component="main" maxWidth="sm">
      <div xs={12} sm={6} lg={8}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} direction={{ md: "row", sm: "row" }}>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                id="date"
                name="date"
                variant="outlined"
                type="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {filteredLabels !== undefined && filteredLabels.length !== 0 && (
                <CustomTextField
                  id="label"
                  select
                  label={t("labelUser")}
                  name="label"
                  fullWidth
                  variant="outlined"
                  value={formik?.values.label}
                  onChange={formik?.handleChange}
                  error={formik.touched.label && Boolean(formik.errors.label)}
                  helperText={formik.touched.label && formik.errors.label}
                >
                  {filteredLabels !== undefined &&
                    filteredLabels.map((option, idx) => (
                      <MenuItem key={idx} value={option.name}>
                        {`${option.name}`}
                      </MenuItem>
                    ))}
                </CustomTextField>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Spacer height="1rem" />
              <Button
                variant="contained"
                disabled={disabled}
                fullWidth
                type="submit"
                sx={{ backgroundColor: colors.red }}
              >
                {t("submit")}
              </Button>
            </Grid>
            {error !== "" && (
              <Grid item xs={12}>
                <p style={{ color: "red" }}>{error}</p>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default UnavailableForm;
