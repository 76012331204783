import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import { LocalizationContext } from "../../context/LocalizationContext";
import UnavailableForm from "../../pages/forms/UnavailableForm";
import { colors } from "./resources/colors";
import { ListAlt } from "@mui/icons-material";
import * as XLSX from 'xlsx';


const styles = {
  height: { md: "40vh", sm: "60vh" },
  borderBottom: `1px solid ${colors.red}`,
  boxShadow: `0 0 10px rgba(${colors.redRgba}, 0.6)`,
};

const UnavailableCard = ({
  labels,
  onClose,
  setUnavailableUsers,
  unavailableUsers,
  userLabels,
}) => {
  const { t } = React.useContext(LocalizationContext);

  const [choosenLabel, setChoosenLabel] = React.useState('')

  const downloadExcel = (data) => {
    const filterData = data.map(val => {
      return {
        Ime: val.name,
        Grad: val.city
      }
    })
    const worksheet = XLSX.utils.json_to_sheet(filterData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${choosenLabel} - nedostupni.xlsx`);
  };

  return (
    <Card sx={styles}>
      <CardActions>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("calendarUnavailable")}
        </Typography>
      </CardActions>
      <CardContent>
        <UnavailableForm
          labels={labels}
          userLabels={userLabels}
          onClose={onClose}
          setChoosenLabel={setChoosenLabel}
          setUnavailableUsers={setUnavailableUsers}
        />

        {unavailableUsers && unavailableUsers.length > 0 && (
          <div style={{ overflowY: "auto", height: "20vh" }}>
            {unavailableUsers.map((e, idx) => (
              <React.Fragment key={idx}>
                <ListItem key={idx}>
                  <ListItemText
                    sx={{ textAlign: "center" }}
                  >{`${e?.name} ${e?.city}`}</ListItemText>
                </ListItem>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Divider
                    sx={{
                      width: {
                        xl: "60%",
                        lg: "60%",
                        md: "60%",
                        sm: "90%",
                        xs: "90%",
                      },
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        )}

        {unavailableUsers && unavailableUsers.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: '10px',
              // marginTop: 'auto'

            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                downloadExcel(unavailableUsers);
              }}
              sx={{ background: colors.red, width: {
                xl: "30%",
                lg: "30%",
                md: "60%",
                sm: "60%",
                xs: "60%",
              }, }}
            >
              {t("buttonFile")}
            </Button>
          </div>
        )}

        {(unavailableUsers === undefined || unavailableUsers?.length === 0) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <ListAlt
              style={{ color: colors.red, fontSize: "5rem", opacity: 0.6 }}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default UnavailableCard;
