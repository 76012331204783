import React, { useEffect } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Spacer from "../infrastructure/Spacer";
import { LocalizationContext } from "../context/LocalizationContext";
import client from "../apis/client";
import history from "../infrastructure/history";
import { ListAlt } from "@mui/icons-material";
import { colors } from "../infrastructure/components/resources/colors";

const NoVideoTestPage = () => {
  const { t } = React.useContext(LocalizationContext);

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      const vidAs = await client.get(`/video_assessment/`);

      if (isActive) {
        if (vidAs.data.length !== 0) {
          history.push("/videotest");
          history.go();
        } else {
          localStorage.removeItem("videoSeconds");
          localStorage.removeItem("videoOdgovori");
          localStorage.removeItem("videoTest");
        }
      }
    };

    if (isActive) loadData();

    return () => {
      isActive = false;
    };
  }, []);
  return (
    <>
      <Spacer height={"15rem"} />
      <Container component="main" maxWidth="md" sx={{ textAlign: "center" }}>
        <ListAlt
          style={{ color: colors.red, fontSize: "3rem", opacity: 0.6 }}
        />
        <Typography component="h1" variant="h6">
          {t("noTest")}
        </Typography>
      </Container>
    </>
  );
};

export default NoVideoTestPage;
