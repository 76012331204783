import React, { useState, useEffect } from "react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ModalMui from "@mui/material/Modal";

import { LocalizationContext } from "../context/LocalizationContext";

import client from "../apis/client";
import history from "../infrastructure/history";
import Spacer from "../infrastructure/Spacer";
import ProfileForm from "./forms/ProfileForm";
import "./styles/HomePage.css";
import { colors } from "../infrastructure/components/resources/colors";
import Spinner from "../infrastructure/components/Spinner";
import ProfileCard from "../infrastructure/components/ProfileCard";
import EditProfile from "./forms/EditProfile";
import CustomModal from "../infrastructure/components/CustomModal";
import DeleteAccount from "./forms/DeleteAccount";
import ChangePassword from "./forms/ChangePassword";
import CalendarCard from "../infrastructure/components/CalendarCard";
import EditBusyDate from "./forms/EditBusyDate";
import ConfirmForm from "./forms/ConfirmForm";
import UnavailableCard from "../infrastructure/components/UnavailableCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: `2px solid ${colors.red}`,
  boxShadow: 24,
  p: 1,
};

const HomePage = (props) => {
  const { t } = React.useContext(LocalizationContext);
  const [profileData, setProfileData] = useState();
  const [userData, setUserData] = useState();
  const [userLabels, setUserLabels] = useState();
  const [assessment, setAssessment] = useState("");
  const [videoAssessment, setVideoAssessment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [busyDates, setBusyDates] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [unavailableUsers, setUnavailableUsers] = useState();

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const handleShowModal = (type) => {
    setModal(true);
    setModalType(type);
  };

  const handleCloseModal = () => {
    setModal(false);
    setModalType("");
  };

  const [reload, setReload] = useState(false);

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      setIsLoading(true);
      const user = await client.get("/auth/users/me/");
      const profile = await client.get(`/profiles/`);
      const as = await client.get(`/assessment/`);
      const vidAs = await client.get(`/video_assessment/`);
      const userLabels = await client.get(`/users_labels/`);

      if (isActive) {
        if (profile.data.length === 0) {
          handleShowModal("postProfile");
        } else {
          setProfileData(await profile.data[0]);
          const busy = await client.get(`/unavailable_dates/`, {
            profile: await profile.data[0].id,
          });
          setBusyDates(await busy.data.sort((a, b) => new Date(b?.date) - new Date(a?.date)));
          setUserData(await user.data);
          setUserLabels(await userLabels.data);
          setAssessment(await as.data.length);
          setVideoAssessment(await vidAs.data.length);
          if (
            localStorage.getItem("minutes") === null &&
            localStorage.getItem("seconds") !== null
          ) {
            localStorage.removeItem("seconds");
          }
          if (localStorage.getItem("videoSeconds") !== null) {
            localStorage.removeItem("videoSeconds");
          }
          if (as.data.length === 0 && vidAs.data.length === 0) {
            localStorage.removeItem("minutes");
            localStorage.removeItem("seconds");
            localStorage.removeItem("videoSeconds");
            localStorage.removeItem("videoOdgovori");
            localStorage.removeItem("videoTest");
            localStorage.removeItem("pismeniOdgovori");
            localStorage.removeItem("pismeniTest");
          }
          if (vidAs.data.length !== 0 && vidAs?.data[vidAs?.data.length - 1]?.id !== JSON.parse(localStorage.getItem("videoTest"))?.data?.id) {
            localStorage.removeItem("videoOdgovori");
            localStorage.removeItem("videoTest");
          }
          if (as.data.length !== 0 && as?.data[as?.data.length - 1]?.id !== JSON.parse(localStorage.getItem("pismeniTest"))?.data?.id) {
            localStorage.removeItem("pismeniOdgovori");
            localStorage.removeItem("pismeniTest");
          }
        }
      }
      setIsLoading(false);
    };

    if (isActive) loadData();

    return () => {
      isActive = false;
    };
  }, [reload]);

  const getTest = async () => {
    if (assessment !== 0) {
      history.push("/test");
      history.go();
    } else {
      history.push("/nema_testa");
      history.go();
    }
  };

  const getVideoTest = () => {
    if (videoAssessment === 0) {
      history.push("/nema_video_testa");
      history.go();
    } else {
      history.push("/videotest");
      history.go();
    }
  };

  const handleDeleteDate = async () => {
    try {
      setDisabled(true);
      const res = await client.delete(
        `/unavailable_dates/${selectedDate.id}/`,
        {
          profile: profileData.id,
        }
      );

      if (res.status === 204) {
        handleCloseModal();
        setReload((s) => !s);
      }
      setDisabled(false);
    } catch (err) {
      setDisabled(false);
    }
  };

  return (
    <div style={{ marginLeft: "2rem", marginRight: "2rem" }}>
      <Spacer height="7rem" />

      {!isLoading && profileData && !userData.is_superuser && (
        <Grid container spacing={10} style={{ justifyContent: "center" }}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => getTest()}
              style={{ backgroundColor: colors.red }}
            >
              {t("startTest")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => getVideoTest()}
              style={{ backgroundColor: colors.red }}
            >
              {t("startVideoTest")}
            </Button>
          </Grid>
        </Grid>
      )}
      <Spacer height={"3rem"} />
      {isLoading && <Spinner />}
      {!isLoading && profileData && (
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent={"center"}
        >
          <Grid item lg={6} md={12}>
            <ProfileCard
              profileData={profileData}
              onEditProfile={() => handleShowModal("editProfile")}
              onChangePassword={() => handleShowModal("changePassword")}
              onDeleteProfile={() => handleShowModal("deleteAccount")}
            />
          </Grid>
          <Grid item lg={6} md={12}>
            {!userData.calendar_access && (
              <CalendarCard
                busyDates={busyDates}
                handleClose={() => setReload((s) => !s)}
                profileData={profileData}
                onEditDate={() => handleShowModal("changeUnavailableDate")}
                onDeleteDate={() => handleShowModal("deleteUnavailableDate")}
                setData={setSelectedDate}
              />
            )}
            {userData.calendar_access && (
              <UnavailableCard
                unavailableUsers={unavailableUsers}
                setUnavailableUsers={setUnavailableUsers}
                labels={userLabels}
                userLabels={userData.labels}
                onClose={() => setReload((s) => !s)}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Modal
        open={modal && modalType === "postProfile"}
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <ProfileForm />
        </Box>
      </Modal>
      <CustomModal
        open={
          modal && modalType !== "postProfile" && modalType !== "editProfile"
        }
        onClose={handleCloseModal}
        text={t(modalType)}
      >
        {modalType === "deleteAccount" && <DeleteAccount />}
        {modalType === "changePassword" && (
          <ChangePassword
            onClose={() => {
              handleCloseModal();
            }}
          />
        )}
        {modalType === "changeUnavailableDate" && (
          <EditBusyDate
            onClose={() => {
              setReload((s) => !s);
              handleCloseModal();
            }}
            data={selectedDate}
            profileData={profileData}
          />
        )}
        {modalType === "deleteUnavailableDate" && (
          <ConfirmForm
            onCancel={handleCloseModal}
            onSubmit={handleDeleteDate}
            disabled={disabled}
          />
        )}
      </CustomModal>

      <ModalMui
        open={modal === true && modalType === "editProfile"}
        onClose={handleCloseModal}
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <EditProfile
            initialValues={profileData}
            onClose={() => {
              setReload((s) => !s);
              handleCloseModal();
            }}
          />
        </Box>
      </ModalMui>
    </div>
  );
};

export default HomePage;
