import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import client from "../apis/client";

import Calendar from "react-calendar";
import "./styles/Calendar.css";

import { Button, Divider } from "@mui/material";
import { LocalizationContext } from "../context/LocalizationContext";
import BusyDateItem from "../infrastructure/components/BusyDateItem";
import { colors } from "../infrastructure/components/resources/colors";
import ListAltIcon from "@mui/icons-material/ListAlt";
import moment from "moment";

const CalendarPage = ({
  busyDates,
  handleClose,
  profileData,
  onEditDate,
  onDeleteDate,
  setData,
}) => {
  const { t } = useContext(LocalizationContext);
  const [value, setValue] = useState(new Date());
  const [error, setError] = useState();

  const minDate = new Date();
  const maxDate = new Date(minDate.getFullYear() + 5, 11, 31);

  const buttonStyle = {
    backgroundColor: colors.red,
    width: "100%",
    color: colors.white,
  };

  const center = {
    display: "flex",
    justifyContent: "center",
  };

  const handleChangeDate = (e) => {
    try {
      setValue(e);
    } catch (error) {}
  };

  const handleEditDate = (data) => {
    try {
      setData(data);
      onEditDate();
    } catch (e) {}
  };

  const handleDeleteDate = (data) => {
    try {
      setData(data);
      onDeleteDate();
    } catch (e) {}
  };

  const handleAddDate = async () => {
    try {
      setError("");
      const data = {
        profile: profileData?.id,
        date: moment(value).format("YYYY-MM-DD"),
      };
      const res = await client.post("/unavailable_dates/", data);

      if (res?.status === 201) {
        handleClose();
      }
    } catch (error) {
      const values = Object.values(error.response.data);
      const keys = Object.keys(error.response.data);

      if (keys !== undefined && values !== undefined) {
        if (keys.length !== 0 && values.length !== 0) {
          if (
            values[0][0].includes("The fields profile, date must make ") &&
            keys[0] === "non_field_errors"
          ) {
            setError(t("errorExistingDate"));
            return;
          } else {
            setError(t("checkData"));
            return;
          }
        }
      }
    }
  };

  return (
    <Grid
      container
      direction={"row"}
      spacing={2}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Grid item md={6} sm={12}>
        <div style={center}>
          <Calendar
            locale={localStorage.getItem("language") === "en" ? "en" : "sr"}
            onChange={(e) => handleChangeDate(e)}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            onClickYear={() => {}}
            onClickMonth={() => {}}
            onClickDecade={() => {}}
            tileClassName={({ date, view }) => {
              const classes = ["react-calendar__tile"];

              if (view === "month") {
                if (date < new Date(value.getFullYear(), value.getMonth(), 1)) {
                  classes.push("react-calendar__tile--past");
                } else if (
                  date > new Date(value.getFullYear(), value.getMonth(), 31)
                ) {
                  classes.push("react-calendar__tile--future");
                }

                const dateString = moment(date).format("YYYY-MM-DD");

                const isBusyDate = busyDates.some(
                  (busyDate) => busyDate.date === dateString
                );

                if (isBusyDate) {
                  classes.push("custom-busy-date");
                }
              }

              return classes.join(" ");
            }}
            prev2Label={null}
            next2Label={null}
          />
        </div>
      </Grid>
      <Grid item md={6} sm={12} sx={{ width: "100%" }}>
        <div style={{ marginTop: "0px", width: "100%" }}>
          <Button style={buttonStyle} onClick={handleAddDate}>
            {t("addBusyDate")}
          </Button>
          {error !== "" && (
            <Grid item xs={12}>
              <p style={{ color: "red" }}>{error}</p>
            </Grid>
          )}
          <div style={{ maxHeight: "20vh", overflowY: "auto" }}>
            {busyDates &&
              busyDates.length > 0 &&
              busyDates.map((d, idx) => (
                <React.Fragment key={idx}>
                  <BusyDateItem
                    text={d.date}
                    onEditPress={() => handleEditDate(d)}
                    onDeletePress={() => handleDeleteDate(d)}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            {busyDates && busyDates.length === 0 && (
              <div style={{ ...center, marginTop: "2rem" }}>
                <ListAltIcon
                  style={{ color: colors.red, fontSize: "5rem", opacity: 0.6 }}
                />
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default CalendarPage;
