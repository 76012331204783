import React from "react";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import CustomTextField from "../../infrastructure/components/CustomTextField";
import { LocalizationContext } from "../../context/LocalizationContext";

import history from "../../infrastructure/history";
import client from "../../apis/client";
import { colors } from '../../infrastructure/components/resources/colors';

const initialValues = {
  email: "",
};

const ForgotPassword = () => {
  const { t } = React.useContext(LocalizationContext);

  const forgotPassword = async (values) => {
    try {
      const email = values.email;
      await client.post(`/auth/users/reset_password/`, { email });
      alert(t("succesForgot"));
      history.push("/");
      history.go();
    } catch (err) {
      if (err.response) {
        alert(JSON.stringify(err.response.data));
      }
    }
  };

  const validationSchema = yup.object({
    email: yup.string().email(t("invalidEmail")).required(t("required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: forgotPassword,
  });

  return (
    <Container component="main" maxWidth="sm">
      <div xs={12} sm={6} lg={8}>
        <form onSubmit={formik.handleSubmit} className="mt-3 m-2">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="email"
                name="email"
                variant="outlined"
                label={t("email")}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              sx={{ backgroundColor: colors.red }}
            >
              {t("submitForgot")}
            </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ForgotPassword;
