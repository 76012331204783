import * as React from "react";
import moment from "moment";
import ReactPlayer from "react-player";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Grid, Stack } from "@mui/material";

import { LocalizationContext } from "../../context/LocalizationContext";
import { colors } from "./resources/colors";

const buttonStyle = {
  backgroundColor: colors.red,
  width: "30%",
};

export default function EducationAccorditionItem(props) {
  const { t } = React.useContext(LocalizationContext);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const downloadFile = async () => {
    window.open(props.item?.file, "_blank");
  };

  return (
    <>
      {props.item !== undefined && (
        <Accordion
          expanded={expanded === `panel${props.item.id}`}
          onChange={handleChange(`panel${props.item.id}`)}
          sx={{
            marginTop: "2rem",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${props.item.id}bh-content`}
            id={`panel${props.item.id}bh-header`}
          >
            <Stack
              spacing={1}
              p={0}
              alignSelf={"center"}
              sx={{
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
                width: "95%",
              }}
            >
              {props.item.league?.name && (
                <Typography
                  sx={{
                    width: {
                      xl: "20%",
                      lg: "20%",
                      md: "20%",
                      sm: "100%",
                      xs: "100%",
                    },
                    flexShrink: 0,
                    color: "text.secondary",
                  }}
                >
                  {moment(props.item.datetime).format("DD. MM. YYYY.")}
                  <br />
                  {props.item?.league?.name && (
                    <span style={{ color: "black" }}>
                      {props.item?.league?.name}
                    </span>
                  )}
                </Typography>
              )}
              <Typography
                sx={{
                  width: {
                    xl: "50%",
                    lg: "50%",
                    md: "50%",
                    sm: "100%",
                    xs: "100%",
                  },
                  color: colors.red,
                  opacity: 0.7,
                  fontSize: "1.5rem",
                  padding: "0.5rem",
                }}
              >
                {props.item.title}
              </Typography>
              {props.item?.label && props.item?.label.length !== 0 && (
                <Stack
                  spacing={1}
                  alignSelf={"center"}
                  sx={{
                    width: {
                      xl: "auto",
                      lg: "auto",
                      md: "auto",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  {props.item?.label.map((l) => (
                    <Typography
                      key={l.id}
                      sx={{
                        borderRadius: "10px 5px",
                        width: "fit-content",
                        padding: "0.5rem",
                        color: colors.white,
                        fontWeight: "bold",
                        backgroundColor: colors.red,
                        opacity: 0.8,
                      }}
                    >
                      #{l.name}
                    </Typography>
                  ))}
                </Stack>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {props.item?.video && (
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <ReactPlayer
                    width="95%"
                    height="auto"
                    url={props.item?.video}
                    controls={true}
                  />
                </Grid>
              )}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                {props.item?.file && (
                  <div style={{ display: "flex", marginBottom: "2rem" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={downloadFile}
                      sx={buttonStyle}
                    >
                      {t("buttonFile")}
                    </Button>
                  </div>
                )}
                {props.item?.referee && (
                  <div style={{ display: "flex" }}>
                    <Typography
                      sx={{ paddingRight: "0.3rem", color: "text.secondary" }}
                    >
                      {t("referee")}
                      {":"}
                    </Typography>
                    <Typography>{props.item.referee.name}</Typography>
                  </div>
                )}
                {props.item?.observer && (
                  <div style={{ display: "flex" }}>
                    <Typography
                      sx={{ paddingRight: "0.3rem", color: "text.secondary" }}
                    >
                      {t("observer")}
                      {":"}
                    </Typography>
                    <Typography>{props.item.observer.name}</Typography>
                  </div>
                )}

                {props.item?.text && (
                  <div style={{ display: "flex" }}>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: props.item.text }}
                      sx={{
                        paddingRight: "0.3rem",
                        color: "text.secondary",
                        fontSize: "1.1rem",
                        whiteSpace: "pre-line",
                        // paddingTop: "2rem",
                      }}
                    />
                  </div>
                )}
              </Grid>
              {props.item?.image && (
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  display="flex"
                  justifyContent={"center"}
                >
                  <Box
                    component="img"
                    width={"70%"}
                    sx={{ paddingTop: "2rem" }}
                    src={props.item?.image}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
