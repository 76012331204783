import React from "react";

import { Button, Stack } from "@mui/material";

import history from "../../history";
import { colors } from "../resources/colors";

const buttonStyle = {
  color: colors.white,
  fontWeight: "1000",
  letterSpacing: 3,
};

const LanguageDropdown = () => {
  console.disableYellowWarnin = true;
  const [locale, setLocale] = React.useState(localStorage.getItem("language"));

  const handleSetLocale = (value) => {
    setLocale(value);
    localStorage.setItem("language", value);
    history.go();
  };

  return (
    <div
      style={{
        backgroundColor: colors.red,
        justifyContent: "flex-end",
        display: "flex",
      }}
    >
      <Stack spacing={0} direction="row">
        <Button
          sx={buttonStyle}
          value="cyr"
          onClick={(event) => handleSetLocale(event.target.value)}
        >
          ћир
        </Button>
        <Button
          sx={buttonStyle}
          value="sr"
          onClick={(event) => handleSetLocale(event.target.value)}
        >
          lat
        </Button>

        <Button
          sx={buttonStyle}
          value="en"
          onClick={(event) => handleSetLocale(event.target.value)}
        >
          eng
        </Button>
      </Stack>
    </div>
  );
};

export default LanguageDropdown;
