import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import { colors } from "./resources/colors";

const Spinner = () => {
  return (
    <Grid
      container
      item
      display={"flex"}
      justifyContent={"center"}
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
    >
      <CircularProgress sx={{ color: colors.red }} size={60} />
    </Grid>
  );
};

export default Spinner;
