const lat = {
    home: 'Početna',
    profile: 'Profil',
    education: 'Edukacija',
    news: 'Vesti',
    tests: 'Testovi',
    logout: 'Odjavi se',
    login: 'Prijavi se',
    register: 'Registruj se',
    email: 'Imejl',
    resetFilter: 'Ukloni filter',
    password: 'Lozinka',
    rememberMe: 'Zapamti me',
    cancel: 'Otkaži',
    submit: 'Potvrdi',
    submitLogin: 'Prijavi se',
    submitRegister: 'Registruj se',
    submitForgot: 'Potvrdi',
    submitResetPassword: 'Potvrdi',
    submitProfile: 'Potvrdi',
    submitChangePassword: 'Potvrdi',
    submitDeleteAccount: 'Potvrdi',
    required: 'Obavezno polje',
    invalidEmail: 'Neispravan imejl',
    tooCommon: 'Ova lozinka je uobičajena',
    alreadyExist: 'Korisnički nalog sa ovom imejl adresom već postoji',
    passwordSimilarEmail: 'Lozinka ne sme biti slična imejlu',
    checkData: 'Proverite da li ste dobro uneli podatke',
    min8: 'Lozinka mora sadržati minimum 8 karaktera',
    max40: 'Lozinka ne sme preći više od 40 karaktera',
    noActiveAccount: 'Ne postoji nalog sa ovim kredencijalima',
    forgotPassword: 'Zaboravljena lozinka',
    dontHaveAccount: 'Ako još nemate nalog ',
    registerPage: 'idite na stranicu za registraciju.',
    succesForgot: 'Poslali smo Vam imejl sa linkom za promenu lozinke! Ako ne primite imejl u narednih par minuta, proverite u porukama promocije ili spam folderu.',
    goToStart: 'Vrati se na početnu stranu',
    name: 'Ime i prezime',
    rePassword: 'Ponovi lozinku',
    language: 'Jezik',
    sr: 'Srpski',
    en: 'Engleski',
    byRegister: 'Registracijom prihvatam ',
    termsAndCondition: 'Uslove i Pravila korišćenja.',
    termsAndConditionModal: 'Uslovi i Pravila korišćenja',
    haveAccount: 'Ako imate nalog ',
    loginPage: 'idite na stranicu za prijavljivanje.',
    currentPassword: 'Trenutna lozinka',
    newPassword: 'Nova lozinka',
    reNewPassword: 'Ponovi novu lozinku',
    matchPassword: 'Lozinke se ne podudaraju',
    successChangePass: 'Uspešno ste promenili lozinku.',
    createAvatar: 'Dodaj sliku',
    male: 'Muški',
    female: 'Ženski',
    other: 'Drugo',
    dateOfBirth: 'Datum rođenja',
    gender: 'Pol',
    city: 'Grad',
    significantTrials: 'Značajna suđenja',
    engagements: 'Angažovanje',
    league: 'Liga',
    startDate: 'Početak angažovanja',
    endDate: 'Kraj angažovanja',
    addEngagement: 'Dodaj angažovanje',
    succesProfileCreate: 'Uspešno ste kreirali profil!',
    succesProfileEdit: 'Uspešno ste izmenili profil!',
    requiredEngagement: 'Obavezno dodati angažovanje',
    hello: 'Zdravo ',
    currentLeague: 'Trenutno angažovanje',
    startTest: 'Započni pismeni test',
    startVideoTest: 'Započni video test',
    editProfile: 'Uredi profil',
    changePassword: 'Izmeni lozinku',
    deleteAccount: 'Obriši nalog',
    invalidCurrentPassword: 'Pogrešna trenutna lozinka',
    successDeleteAccount: 'Uspešno ste obrisali nalog.',
    noTest: 'Nažalost, trenutno nemate ni jedan test.',
    notFound: 'Tražena stranica nije pronađena.',
    alreadyExistEng: 'Izabrano angažovanje se nalazi u unetim angažovanjima.',
    noMoreEngWomen: 'Ne možete biti trenutno angažovani u više od dve lige',
    noMoreEngMan: 'Ne možete biti trenutno angažovani u više od jedne lige',
    checkDate: 'Proverite unete podatke.',
    smallDate: 'Kraj angažovanja ne može biti manji od početka angažovanja',
    serverError: 'Serverska greška',
    emptyPosts: 'Nema edukativnih članaka',
    buttonFile: 'Preuzmi fajl',
    appTitle1: 'Fudbalski Savez Srbije',
    appTitle2: 'Portal za sudije',
    match: 'Utakmica',
    referee: 'Sudija',
    observer: 'Posmatrač',
    assist: 'Pomoćne sudijw',
    label: 'Tipovi',
    submitFilter: 'Primeni filter',
    generalSponsor: 'Generalni sponzor',
    sponsor: 'Sponzori',
    mediaSponsor: 'Medijski sponzori',
    addBusyDate: 'Nedostupan',
    current: 'Trenutno',
    calendarUnavailable: 'Kalendar nedostupnosti',
    date: 'Datum',
    changeUnavailableDate: 'Promeni datum',
    deleteUnavailableDate: 'Obriši datum',
    errorExistingDate: 'Datum već postoji',
    labelUser: 'Labela',

};

  export default lat;
