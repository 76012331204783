import React, { useContext, useEffect, useState } from "react";

import { Button, Grid, MenuItem } from "@mui/material";
import CustomOutlinedTextField from "./CustomOutlinedTextField";
import { LocalizationContext } from "../../context/LocalizationContext";
import { colors } from "./resources/colors";
import client from "../../apis/client";
import CustomTextField from "./CustomTextField";
import Spinner from "./Spinner";

const boxStyle = {
  borderRadius: "20px 20px",
  boxShadow: `0 0 20px 1px grey`,
  padding: 3,
  marginLeft: "0px",
};

const EductationFilterForm = (props) => {
  const { t } = useContext(LocalizationContext);
  const [referees, setReferees] = useState();
  const [observers, setObservers] = useState();
  const [labels, setLabels] = useState();
  const [leagues, setLeagues] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const noFilter = {
    id: 'id',
    name: "-------"
  }

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      setIsLoading(true);
      const ref = await client.get("/education/persons/?person_type=REFEREE");
      const obs = await client.get("/education/persons/?person_type=OBSERVER");
      const lab = await client.get("/education/labels/");
      const leag = await client.get("/education/leagues/");
      if (isActive) {
        setReferees([noFilter, ...ref.data]);
        setObservers([noFilter, ...obs.data]);
        setLabels([noFilter, ...lab.data]);
        setLeagues([noFilter, ...leag.data]);
      }
      setIsLoading(false);
    };
    if (isActive) loadData();

    return () => {
      isActive = false;
    };
  }, []);

  return (
    <form onSubmit={props.formik.handleSubmit} onReset={() => {props.formik.handleReset(); props.handleCloseForm()}}>
      {isLoading && <Grid container spacing={1} sx={{...boxStyle, height: '20vh'}}><Spinner /></Grid>}
      {!isLoading && (
        <Grid container spacing={1} sx={boxStyle}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            {leagues !== undefined && leagues.length !== 0 && (
              <CustomTextField
                id="league"
                select
                label={t("league")}
                name="leagues"
                fullWidth
                variant="outlined"
                value={props.formik?.values.leagues}
                onChange={props.formik?.handleChange}
              >
                {leagues !== undefined &&
                  leagues.map((option, idx) => (
                    <MenuItem key={idx} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <CustomOutlinedTextField
              width={"100%"}
              label={t("match")}
              id={"title"}
              name={"title"}
              formik={props.formik}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            {referees !== undefined && referees.length !== 0 && (
              <CustomTextField
                id="referee"
                select
                label={t("referee")}
                name="referee"
                fullWidth
                variant="outlined"
                value={props.formik?.values.referee}
                onChange={props.formik?.handleChange}
              >
                {referees !== undefined &&
                  referees.map((option, idx) => (
                    <MenuItem key={idx} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            {observers !== undefined && observers.length !== 0 && (
              <CustomTextField
                id="observer"
                select
                label={t("observer")}
                name="observer"
                fullWidth
                variant="outlined"
                value={props.formik?.values.observer}
                onChange={props.formik?.handleChange}
              >
                {observers !== undefined &&
                  observers.map((option, idx) => (
                    <MenuItem key={idx} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            {labels !== undefined && labels.length !== 0 && (
              <CustomTextField
                id="label"
                select
                label={t("label")}
                name="label"
                fullWidth
                variant="outlined"
                value={props.formik?.values.label}
                onChange={props.formik?.handleChange}
              >
                {labels !== undefined &&
                  labels.map((option, idx) => (
                    <MenuItem key={idx} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={4} sm={12} xs={12}></Grid>
          <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
            <Button
              type="reset"
              variant="contained"
              style={{ backgroundColor: colors.white, marginTop: "2rem", color: colors.red }}
            >
              {t("resetFilter")}
            </Button>
          </Grid>
          <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              style={{ backgroundColor: colors.red, marginTop: "2rem" }}
            >
              {t("submitFilter")}
            </Button>
          </Grid>
          <Grid item xl={0} lg={0} md={0} sm={0} xs={0}></Grid>
        </Grid>
      )}
    </form>
  );
};

export default EductationFilterForm;
