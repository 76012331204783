import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import I18n from "i18n-js";

import { LocalizationContext } from "./context/LocalizationContext";
import history from "./infrastructure/history";
import { Provider as AuthProvider } from "./context/AuthContext";
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@mui/material/styles";

import MainNavigator from "./infrastructure/navigator/MainNavigator";
import HomePage from "./pages/HomePage";
import TestPage from "./pages/TestPage";
import HomeAuthPage from "./pages/HomeAuthPage";
import LanguageDropdown from "./infrastructure/components/ui-components/LanguageDropdown";
import AppStore from "./pages/AppStore";
import ResetPassword from "./pages/auth/ResetPassword";
import NoTestPage from "./pages/NoTestPage";
import NoVideoTestPage from "./pages/NoVideoTestPage";
import Page404 from "./pages/Page404";
import VideoTestPage from "./pages/VideoTestPage";
import EducationPage from "./pages/EducationPage";
import client_auth from "./apis/client_auth";
import Sponsors from "./infrastructure/components/ui-components/Sponsors";
import { sponsors } from "./infrastructure/components/resources/images";
import CustomBlueLines from "./infrastructure/components/CustomBlueLines";

const App = () => {
  const [locale, setLocale] = React.useState("cyr");
  var isActive = useRef(true);

  const theme = createMuiTheme();

  React.useEffect(() => {
    client_auth.get("/banners");
    const interval = setInterval(() => {
      client_auth.get("/banners");
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const localizationContext = React.useMemo(
    () => ({
      t: (scope, options) => I18n.t(scope, { locale, ...options }),
      locale,
      setLocale,
    }),
    [locale]
  );

  React.useEffect(() => {
    let language = localStorage.getItem("language");
    if (isActive.current === true) setLocale(language || "cyr");
    return () => {
      isActive.current = false;
    };
  }, []);

  let routes;

  if (localStorage.getItem("refreshToken") === null) {
    routes = (
      <>
        <div>
          <LanguageDropdown />
        </div>
        <Switch>
          <Route exact path="/" component={HomeAuthPage} />
          <Route
            path="/password/reset/confirm/:uid/:token"
            component={ResetPassword}
          />
          <Route component={HomeAuthPage} />
        </Switch>
        <CustomBlueLines />
        <Sponsors images={sponsors} />
      </>
    );
  } else {
    routes = (
      <>
        {history.location.pathname === "/test" ||
        history.location.pathname === "/videotest" ? null : (
          <MainNavigator />
        )}
        <Switch>
          <Route exact path="/" component={HomePage}></Route>
          <Route path="/test" component={TestPage} />
          <Route path="/edukacija" component={EducationPage} />
          <Route path="/videotest" component={VideoTestPage} />
          <Route path="/nema_testa" component={NoTestPage} />
          <Route path="/nema_video_testa" component={NoVideoTestPage} />
          <Route component={Page404} />
        </Switch>
        {history.location.pathname === "/test" ||
        history.location.pathname === "/videotest" ? null : (
          <>
            <CustomBlueLines />
            <Sponsors images={sponsors} />
          </>
        )}
      </>
    );
  }

  return (
    <div style={{ maxWidth: "100%", width: "100%" }}>
      <Router>
        <ThemeProvider theme={theme}>
          <LocalizationContext.Provider value={localizationContext}>
            <AuthProvider>
              <AppStore>
                <main>{routes}</main>
              </AppStore>
            </AuthProvider>
          </LocalizationContext.Provider>
        </ThemeProvider>
      </Router>
    </div>
  );
};

export default App;
