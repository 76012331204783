const eng = {
    home: 'Home',
    profile: 'Profile',
    education: 'Education',
    news: 'News',
    tests: 'Tests',
    logout: 'Logout',
    login: 'Login',
    register: 'Register',
    email: 'Email',
    resetFilter: 'Reset',
    password: 'Password',
    rememberMe: 'Remember me',
    cancel: 'Cancel',
    submit: 'Submit',
    submitLogin: 'Submit',
    submitRegister: 'Submit',
    submitForgot: 'Submit',
    submitResetPassword: 'Submit',
    submitProfile: 'Submit',
    submitChangePassword: 'Submit',
    submitDeleteAccount: 'Submit',
    required: 'Required',
    invalidEmail: 'Invalid email',
    tooCommon: 'This password is too common',
    alreadyExist: 'A user with that email already exists.',
    passwordSimilarEmail: 'The password is too similar to the email',
    checkData: 'Make sure you have entered the data correctly',
    min8: 'Password must be at least 8 characters',
    max40: 'Password must not exceed 40 characters',
    noActiveAccount: 'No active account found with the given credentials',
    forgotPassword: 'Forgot password',
    dontHaveAccount: 'If you don\'t have an account yet, please ',
    registerPage: 'go to the register page.',
    succesForgot: 'We have e-mailed your password reset link! If you do not receive the email within a few minutes, please check your spam folder or promotions.',
    goToStart: 'Go to the Start Page',
    name: 'Name',
    rePassword: 'Repeat password',
    language: 'Language',
    sr: 'Serbian',
    en: 'English',
    byRegister: 'By registering, I accept ',
    termsAndCondition: 'Terms and Condition.',
    termsAndConditionModal: 'Terms and Condition',
    haveAccount: 'If you have an account, please ',
    loginPage: 'go to the login page',
    currentPassword: 'Current password',
    newPassword: 'New password',
    reNewPassword: 'Repeat new password',
    matchPassword: 'Passwords didn\'t match',
    successChangePass: 'Your password has been changed successfully.',
    createAvatar: 'Add image',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    dateOfBirth: 'Date of birth',
    gender: 'Gender',
    city: 'City',
    significantTrials: 'Significant trials',
    engagements: 'Engagements',
    league: 'League',
    startDate: 'Start date',
    endDate: 'End date',
    addEngagement: 'Add engagement',
    succesProfileCreate: 'You succesfully create profile!',
    succesProfileEdit: 'You succesfully edit profile!',
    requiredEngagement: 'Required engagements',
    hello: 'Hello ',
    currentLeague: 'Current engagement',
    startTest: 'Start test',
    startVideoTest: 'Start video test',
    editProfile: 'Edit profile',
    changePassword: 'Change password',
    deleteAccount: 'Delete account',
    invalidCurrentPassword: 'Invalid current password',
    successDeleteAccount: 'You successfully delete your account.',
    noTest: 'Sorry, you don\'t have test.',
    notFound: 'Page not found.',
    alreadyExistEng: 'You have already entered the selected engagement.',
    noMoreEngWomen: 'You can\'t be currently engaged in more than two league',
    noMoreEngMan: 'You can\'t be currently engaged in more than one league',
    checkDate: 'Check entires',
    smallDate: 'End date can\'t be smaller than a start date',
    serverError: 'Server error',
    emptyPosts: 'There is no any education information',
    buttonFile: 'Download file',
    appTitle1: 'Football Association of Serbia',
    appTitle2: 'Portal for referees',
    match: 'Match',
    referee: 'Referee',
    observer: 'Observer',
    assist: 'Assists',
    label: 'Tips',
    submitFilter: 'Apply filter',
    generalSponsor: 'General sponsor',
    sponsor: 'Sponsor',
    mediaSponsor: 'Media sponsor',
    addBusyDate: 'Unavailable',
    current: 'Current',
    calendarUnavailable: 'Unavailability calendar',
    date: 'Date',
    changeUnavailableDate: 'Change date',
    deleteUnavailableDate: 'Delete date',
    errorExistingDate: 'Date already exists',
    labelUser: 'Label',

};

  export default eng;
