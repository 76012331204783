import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import client from "../apis/client";
import history from "../infrastructure/history";
import Spacer from "../infrastructure/Spacer";

import SingleChoiceAnswer from "../infrastructure/components/singlecheck/SingleChoiceAnswer";
import { shuffle } from "../infrastructure/components/resources/shuffle";
import ReactPlayer from "react-player";

import "./styles/VideoTestPage.css";
import { colors } from "../infrastructure/components/resources/colors";
import CustomTextField from '../infrastructure/components/CustomTextField';

const pitanja = [
  {
    id: 0,
    text: "Техничка одлука?",
    choices: [
      ["NEMA PREKRSAJA", "Нема прекршаја"],
      ["ISU", "Индиректан слободан ударац"],
      ["DSU", "Директан слободан ударац"],
      ["KU", "Казнени ударац"],
    ],
    choice: "technical_choice",
  },
  {
    id: 1,
    text: "Дисциплинска одлука?",
    choices: [
      ["NEMA", "Без картона"],
      ["ZK", "Жути картон"],
      ["CK", "Црвени картон"],
    ],
    choice: "disciplinary_choice",
  },
  {
      id: 2,
      text: 'ВАР одлука? (ВАР одлука подразумева да судија није донео исправну одлуку)',
      choices: [
          ['NEMA', 'Нема VAR учешћа'],
          ['VAR_OFR', 'VAR OFR'],
          ['VAR_INTERVENCIJA', 'VAR интервенција']
      ],
      choice: 'var_choice'
  }
];

const VideoTestPage = () => {
  const [questions, setQuestions] = useState();
  const [examData, setExamData] = useState();
  const [time, setTime] = useState();
  const [assessmentId, setAssessmentID] = useState("");
  const [buttonState, setButtonState] = useState(false);
  const [singleChoiceArray, setSingleChoiceArray] = React.useState(
    JSON.parse(localStorage.getItem("videoOdgovori")) ?? []
  );
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isVideoFinished, setIsVideoFinished] = useState(false);
  const [isTestFinished, setIsTestFinished] = useState(false);

  const [pin, setPin] = useState("");
  const [errorPin, setErrorPin] = useState("");

  const [testStateLength, setTestStateLength] = useState(
    JSON.parse(localStorage.getItem("videoOdgovori"))?.length + 1 || 1
  );

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      const as = await client.get(`/video_assessment/`);

      if (isActive) {
        if (as.data.length === 0) {
          history.push("/");
          history.go();
        } else {
          const exam =
            JSON.parse(localStorage.getItem("videoTest")) ??
            (await client.get(
              `/video_assessment/${await as.data[as.data.length - 1].id}/`
            ));

          localStorage.setItem("videoTest", JSON.stringify(exam));
          let odgovorenaPitanja = singleChoiceArray.map((v) => v.question);

          setQuestions(
            localStorage.getItem("videoTest") !== null &&
              localStorage.getItem("videoTest") !== undefined
              ? await exam.data?.questions.filter(
                  (q) => !odgovorenaPitanja.includes(q.id)
                )
              : shuffle(
                  await exam.data?.questions.filter(
                    (q) => !odgovorenaPitanja.includes(q.id)
                  )
                )
          );
          // questions && setTestStateLength((await exam.data?.questions?.length - questions && questions?.length) === 0 ? 1 : (await exam?.data?.questions?.length - await questions?.length))
          setExamData(await exam.data.exam);
          setAssessmentID(await exam.data.id);
        }
      }
    };

    if (isActive) {
      loadData();
    }

    return () => {
      isActive = false;
    };
  }, []);

  const singleChoiceHandler = useCallback(
    (value, obj) => {
      if (value !== "") {
        setSingleChoiceArray((prev) => [
          ...prev.filter((c) => c.question !== obj.question),
          obj,
        ]);
      }
    },
    [setSingleChoiceArray]
  );

  const array = [...singleChoiceArray];

  useEffect(() => {
    if (
      questions !== undefined &&
      singleChoiceArray.length !== 0 &&
      currentQuestion !== questions.length - 1
    ) {
      updateCurrentQuestion();
      setTestStateLength(testStateLength + 1);
    }
    if (questions !== undefined && currentQuestion === questions.length - 1) {
      setIsTestFinished(true);
    }
  }, [singleChoiceArray]);

  const updateCurrentQuestion = () => {
    if (currentQuestion < questions.length - 1)
      setCurrentQuestion(currentQuestion + 1);
    setIsVideoFinished(false);
    localStorage.setItem("videoOdgovori", JSON.stringify(singleChoiceArray));
  };
  const sendTest = async () => {
    try {
      setButtonState(true);

      if (examData.has_pin && pin === "") {
        setErrorPin("Пин је обавезан");
        setButtonState(false);
      }
      else if (examData.has_pin && pin.length > 4) {
        setErrorPin("ПИН има највише 4 цифре");
        setButtonState(false);
      } else {

      const test = {
        video_answers: array,
        time_of_test: moment(new Date()).format("YYYY-MM-DDThh:mm"),
        assessment: assessmentId,
      };

      const res = await client.post("/video_submission/", test);
      if (res.status === 201) {
        alert("Uspešno ste završili test!");
        setPin("");
        setButtonState(false);
        localStorage.removeItem("minutes");
        localStorage.removeItem("seconds");
        localStorage.removeItem("videoOdgovori");
        localStorage.removeItem("videoTest");
        window.close();
        history.push("/");
        history.go();
      }

    }
    } catch (err) {
      if (err.response) {
        alert(JSON.stringify(err.response.data));
        setButtonState(false);
      }
    }
  };

  return (
    <div className="back-fss">
      <div className="centerContent">
        <Container
          component="main"
          maxWidth="xl"
          style={{ backgroundColor: "white", borderRadius: "15px 15px" }}
        >
          <Spacer height={"2rem"} />
          {examData !== undefined && (
            <Typography textAlign={"center"} variant="h4">
              {examData.name}
            </Typography>
          )}
          <Spacer height={"2rem"} />
          {questions !== undefined && (
            <>
              {questions !== undefined &&
                !isVideoFinished &&
                !isTestFinished && (
                  <div key={questions[currentQuestion].id}>
                    <div style={{ paddingBottom: "1rem", marginLeft: '2rem' }}>
                      {`Видео број ${testStateLength}:`}{" "}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <ReactPlayer
                        width="130vh"
                        height="auto"
                        playing={true}
                        muted
                        url={questions[currentQuestion].video}
                        // controls={true}
                        onEnded={() => setIsVideoFinished(true)}
                      />
                    </div>
                  </div>
                )}

              {questions !== undefined &&
                isVideoFinished &&
                !isTestFinished && (
                  <div
                    style={{
                      marginLeft: "2rem",
                      marginRight: "2rem",
                      width: "100vh",
                    }}
                  >
                    <SingleChoiceAnswer
                      questionId={questions[currentQuestion].id}
                      answersArray={pitanja}
                      singleChoiceArray={singleChoiceArray}
                      setSingleChoiceArray={setSingleChoiceArray}
                      onInputChange={singleChoiceHandler}
                    ></SingleChoiceAnswer>
                  </div>
                )}

              {questions !== undefined && isTestFinished && (
               <div
               style={{
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "center",
                 padding: "10rem",
               }}
             >
               {examData?.has_pin && (
                 <CustomTextField
                   variant="outlined"
                   name="pin_code"
                   label={"ПИН"}
                   onChange={(e) => setPin(e.target.value)}
                   error={errorPin !== ""}
                   helperText={errorPin !== "" && errorPin}
                 />
               )}
               {examData?.has_pin && <div style={{ height: "1rem" }}></div>}
               <Button
                 color="primary"
                 variant="contained"
                 disabled={buttonState}
                 onClick={sendTest}
                 style={{ backgroundColor: colors.red }}
               >
                 Заврши тест
               </Button>
             </div>
              )}
            </>
          )}
          <Spacer height={"2rem"} />
        </Container>
      </div>
    </div>
  );
};

export default VideoTestPage;
