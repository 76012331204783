import React from "react";
import { colors } from "./resources/colors";
import { fss_logo_dots } from "./resources/images";

const CustomBlueLines = () => {
  return (
    <div style={{ marginTop: "5rem" }}>
      <div style={styles.first}>
        <div style={styles.firstSkewed}></div>
      </div>
      <div style={styles.sec}>
        <div style={styles.secSkewed}></div>
      </div>
      <div style={styles.third}>
        <img alt="logo-dots" src={fss_logo_dots.src} />
      </div>
    </div>
  );
};

const styles = {
  first: {
    width: "25vw",
    height: "40px",
    position: "relative",
    overflow: "hidden",
  },
  firstSkewed: {
    position: "absolute",
    content: "",
    backgroundColor: colors.blue,
    width: "100%",
    height: "100%",
    transform: "skewX(-60deg)", // Adjusted skew direction
    transformOrigin: "top right", // Adjusted origin
  },
  sec: {
    width: "50vw",
    height: "40px",
    position: "relative",
    overflow: "hidden",
  },
  secSkewed: {
    position: "absolute",
    content: "",
    backgroundColor: colors.blue,
    width: "100%",
    height: "100%",
    transform: "skewX(-60deg)", // Adjusted skew direction
    transformOrigin: "top right", // Adjusted origin
  },
  third: {
    backgroundColor: colors.blue,
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    paddingTop: "5vh",
    paddingBottom: "5vh",
  },
};

export default CustomBlueLines;
