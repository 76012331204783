export const logo = {
  src: require("../../../images/logo.png"),
};

export const fss_back = {
  src: require("../../../images/back-fss-without-text.png"),
};

export const fss_logo_dots = {
  src: require("../../../images/FSS_logo_img_dots.png"),
};

export const sponsors = [
  {
    src: require("../../../images/telekom.png"),
    name: "main",
    link: "https://mts.rs/O-Telekomu"
  },
  {
    src: require("../../../images/mozzart.png"),
    name: "sponsor",
    link: "https://www.mozzartbet.com/sr#/"
  },
  {
    src: require("../../../images/nektar.png"),
    name: "sponsor",
    link: "https://www.nektarpivo.com/"
  },
  {
    src: require("../../../images/acg.png"),
    name: "sponsor",
    link: "https://www.tvarenasport.com/"
  },

  {
    src: require("../../../images/puma.png"),
    name: "sponsor",
    link: "http://www.n-sport.net/"
  },
  {
    src: require("../../../images/vivo.png"),
    name: "sponsor",
    link: "https://www.vivo.com/rs"
  },
  {
    src: require("../../../images/vodavoda.png"),
    name: "sponsor",
    link: "https://vodavoda.com/"
  },
  {
    src: require("../../../images/kina.jpg"),
    name: "sponsor",
    link: "https://www.dangbei.com/"
  },
  {
    src: require("../../../images/blic.png"),
    name: "media",
    link: "https://www.blic.rs/"
  },
  {
    src: require("../../../images/republika.jpg"),
    name: "media",
    link: "https://www.republika.rs/"
  },
  {
    src: require("../../../images/srTelegraf.png"),
    name: "media",
    link: "https://www.republika.rs/"
  },
  {
    src: require("../../../images/vecernjeNovosti.png"),
    name: "media",
    link: "https://www.novosti.rs/"
  },
];
