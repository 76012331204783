import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Divider,
  CardActions,
  IconButton,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { LocalizationContext } from "../../context/LocalizationContext";
import moment from "moment";
import { Delete, Edit, Lock } from "@mui/icons-material";
import { colors } from './resources/colors';


const ProfileCard = ({
  profileData,
  onEditProfile,
  onDeleteProfile,
  onChangePassword,
}) => {
  const {
    name,
    date_of_birth,
    gender,
    city,
    current_league,
    significant_trials,
    engagements,
    avatar,
  } = profileData;
  const { t } = React.useContext(LocalizationContext);

  return (
    <Card sx={{...styles.styles, overflowY: "auto" }}>
      <CardActions>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("profile")}
        </Typography>
        <ListItem sx={styles.flexEnd}>
          <ListItemIcon>
            <Edit onClick={onEditProfile} sx={{ cursor: "pointer" }} />
          </ListItemIcon>
          <ListItemIcon>
            <Lock onClick={onChangePassword} sx={{ cursor: "pointer" }} />
          </ListItemIcon>
          <ListItemIcon>
            <Delete onClick={onDeleteProfile} sx={{ cursor: "pointer" }} />
          </ListItemIcon>
        </ListItem>
      </CardActions>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: {
            sm: "column",
            xs: "column",
            md: "row",
            lg: "row",
            xl: "row",
          },
        }}
        avatar={
          <Avatar alt={name} sx={{ width: 200, height: 200 }} src={avatar} />
        }
        title={<Typography variant="h5">{name}</Typography>}
        subheader={
          <>
            <Typography variant="body1">
              {moment(date_of_birth).format("DD. MM. YYYY.")}
            </Typography>
            <Typography variant="body1">
              {gender === "0" ? t("male") : t("female")}
            </Typography>
            <Typography variant="body1">{city ?? "/"}</Typography>
            {significant_trials && (
              <Typography variant="body1">
                {`${t("significantTrials")}: ${significant_trials}`}
              </Typography>
            )}
            {current_league && current_league !== "" && (
              <Typography variant="body1">
                {t("currentLeague")}:{" "}
                <span style={{ color: "black" }}>
                  {current_league.slice(0, -1).replace(";", ", ")}
                </span>
              </Typography>
            )}
          </>
        }
      />
      {/* <CardContent>
        <Typography variant="h6">{t("engagements")}</Typography>
        {engagements.map((engagement, index) => (
          <div key={index}>
            <Typography variant="body1" gutterBottom>
              <span style={{ color: "gray" }}>{`${t("league")}: `}</span>
              {engagement.league_name}
            </Typography>
            <Typography variant="body2">
              {`${moment(engagement.start_date).format("DD. MM. YYYY.")} - ${
                engagement?.end_date
                  ? moment(engagement.end_date).format("DD. MM. YYYY.")
                  : t("current")
              }`}
            </Typography>
          </div>
        ))}
      </CardContent> */}
    </Card>
  );
};

const styles = {
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex",
    marginRight: "0px",
    marginLeft: "auto",
  },
  styles: {
    height: { md: "40vh", sm: "60vh" },
    borderBottom: `1px solid ${colors.red}`,
    boxShadow: `0 0 10px rgba(${colors.redRgba}, 0.6)`
  }
};
export default ProfileCard;
