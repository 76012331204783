import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import client from "../apis/client";
import history from "../infrastructure/history";
import Spacer from "../infrastructure/Spacer";
import "./styles/TestPage.css";
import MultiChoiceAnswer from "../infrastructure/components/multicheck/MultiChoiceAnswer";
import { shuffle } from "../infrastructure/components/resources/shuffle";
import { colors } from "../infrastructure/components/resources/colors";
import CustomTextField from "../infrastructure/components/CustomTextField";

const TestPage = (props) => {
  const [questions, setQuestions] = useState([]);
  const [examData, setExamData] = useState(null);
  const [assessmentId, setAssessmentID] = useState("");
  const [buttonState, setButtonState] = useState(false);
  const [singleChoiceArray, setSingleChoiceArray] = useState(
    JSON.parse(localStorage.getItem("pismeniOdgovori")) || []
  );
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isTestFinished, setIsTestFinished] = useState(false);
  const [pin, setPin] = useState("");
  const [errorPin, setErrorPin] = useState("");

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      try {
        const as = await client.get("/assessment/");
        if (!isActive) return;

        if (as.data.length === 0) {
          history.push("/");
          history.go();
        } else {
          const exam =
            JSON.parse(localStorage.getItem("pismeniTest")) ||
            (await client.get(
              `/assessment/${as.data[as.data.length - 1].id}/`
            ));

          localStorage.setItem("pismeniTest", JSON.stringify(exam));
          const odgovorenaPitanja = singleChoiceArray.map((v) => v.question);

          const filteredQuestions = await (localStorage.getItem("pismeniTest")
            ? exam.data?.questions.filter(
                (q) => !odgovorenaPitanja.includes(q.id)
              )
            : exam.data?.questions.filter(
                (q) => !odgovorenaPitanja.includes(q.id)
              ));

          // : shuffle(
          //     exam.data?.questions.filter(
          //       (q) => !odgovorenaPitanja.includes(q.id)
          //     )
          //   ));

          setQuestions(filteredQuestions);
          setExamData(exam.data.exam);
          setAssessmentID(exam.data.id);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    if (isActive) {
      loadData();
    }

    return () => {
      isActive = false;
    };
  }, []);

  // const singleChoiceHandler = useCallback(
  //   (value, obj) => {
  //     if (value !== "") {
  //       setSingleChoiceArray((prev) =>
  //         prev.map((c) => (c.question === obj.question ? obj : c))
  //       );
  //     }
  //   },
  //   [setSingleChoiceArray]
  // );

  const onInputChange = (updatedChoiceArray) => {
    setSingleChoiceArray(updatedChoiceArray);
  };

  const updateCurrentQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setIsTestFinished(false);
    } else {
      setIsTestFinished(true);
    }
    localStorage.setItem("pismeniOdgovori", JSON.stringify(singleChoiceArray));
  };

  const sendTest = async () => {
    try {
      setButtonState(true);

      const array = singleChoiceArray.filter(
        (item) => item.choice_answer.choices !== ""
      );

      if (examData.has_pin && pin === "") {
        setErrorPin("Пин је обавезан");
        setButtonState(false);
      }
      else if (examData.has_pin && pin.length > 4) {
        setErrorPin("ПИН има највише 4 цифре");
        setButtonState(false);
      } else {
        const test = {
          answers: array,
          time_of_test: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
          assessment: assessmentId,
          ...(examData.has_pin && { pin_code: pin }),
        };

        const res = await client.post("/submission/", test);
        if (res.status === 201) {
          alert("Uspešno ste završili test!");
          setPin("");
          setButtonState(false);
          localStorage.removeItem("minutes");
          localStorage.removeItem("seconds");
          localStorage.removeItem("pismeniOdgovori");
          localStorage.removeItem("pismeniTest");
          window.close();
          history.push("/");
          history.go();
        }

       
      }
    } catch (err) {
      if (err.response) {
        alert(JSON.stringify(err.response.data));
        setButtonState(false);
      }
    }
  };

  return (
    <div className="back-fss">
      <div className="centerContent">
        <Container
          component="main"
          maxWidth="lg"
          style={{ backgroundColor: "white", borderRadius: "15px 15px" }}
        >
          <Spacer height={"3rem"} />
          {examData && (
            <Typography textAlign={"center"} variant="h4">
              {examData?.name}
            </Typography>
          )}
          <Spacer height={"3rem"} />
          {questions.length > 0 && (
            <>
              {!isTestFinished && (
                <div
                  style={{
                    marginLeft: "2rem",
                    marginRight: "2rem",
                    width: "100vh",
                  }}
                >
                  <MultiChoiceAnswer
                    questionId={questions[currentQuestion].id}
                    questionText={questions[currentQuestion].text}
                    answersArray={questions[currentQuestion].choices}
                    singleChoiceArray={singleChoiceArray}
                    setSingleChoiceArray={setSingleChoiceArray}
                    onInputChange={onInputChange}
                    onNextClick={updateCurrentQuestion}
                  />
                </div>
              )}

              {isTestFinished && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "10rem",
                  }}
                >
                  {examData?.has_pin && (
                    <CustomTextField
                      variant="outlined"
                      name="pin_code"
                      label={"ПИН"}
                      onChange={(e) => setPin(e.target.value)}
                      error={errorPin !== ""}
                      helperText={errorPin !== "" && errorPin}
                    />
                  )}
                  {examData?.has_pin && <div style={{ height: "1rem" }}></div>}
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={buttonState}
                    onClick={sendTest}
                    style={{ backgroundColor: colors.red }}
                  >
                    Заврши тест
                  </Button>
                </div>
              )}
            </>
          )}
          <Spacer height={"2rem"} />
        </Container>
      </div>
    </div>
  );
};

export default TestPage;
