import React from "react";

import { LocalizationContext } from "../context/LocalizationContext";
import { logo } from "../infrastructure/components/resources/images";

import Spacer from "../infrastructure/Spacer";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import history from "../infrastructure/history";
import { colors } from "../infrastructure/components/resources/colors";
import SignInPage from './auth/SignInPage';

const HomeAuthPage = () => {
  const { t } = React.useContext(LocalizationContext);

  return (
    <Container component="main" maxWidth="xl">
      <Grid container spacing={3}>
       
        <Grid
          item
          display="flex"
          justifyContent={"center"}
          marginTop={"5rem"}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Box component="img" height="80px" src={logo.src} />
        </Grid>
        <Grid
          item
          container
          direction="column"
          marginTop={"1rem"}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Typography textAlign={"center"} fontSize="1.5rem">
            {t("appTitle2")}
          </Typography>
          <Typography textAlign={"center"} fontSize="2rem">
            {t("appTitle1")}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex"
          justifyContent={"center"}>
          <SignInPage />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeAuthPage;
