import React from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Spacer from "../infrastructure/Spacer";
import { LocalizationContext } from "../context/LocalizationContext";

const Page404 = () => {
  const { t } = React.useContext(LocalizationContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "1rem",
          marginRight: "4rem",
        }}
      >
        <a href="/" className="link_text">
          {t("goToStart")}
        </a>
      </div>
      <Spacer height={"15rem"} />
      <Container component="main" maxWidth="md">
        <Typography component="h1" variant="h2" textAlign={'center'}>
          {t("notFound")}
        </Typography>
      </Container>
    </>
  );
};

export default Page404;
