import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #b82f3f',
    boxShadow: 2,
    p: 3
};



const CustomModal = props => {
    return (
        <Modal 
            open={props.open}
            onClose={props.onClose}
            style={{ overflow: 'scroll' }}
        >
            <Box sx={style}>
                <Typography
                    variant='h5'
                    align='center'
                    sx={{
                        padding: '2rem'
                    }}
                >
                    {props.text}
                </Typography>
                {props.children}
            </Box>
        </Modal>
    );
}

export default CustomModal;