import React from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { colors } from "../resources/colors";
import { LocalizationContext } from "../../../context/LocalizationContext";
import ImagesShow from "../ImagesShow";
import Spacer from "../../Spacer";
import CustomBlueLines from "../CustomBlueLines";

const shadowSponsor = {
  boxShadow:
    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);",
  pb: 10,
  marginTop: "10rem",
};

const Sponsors = (props) => {
  const { images } = props;
  const { t } = React.useContext(LocalizationContext);

  const main = images.filter((i) => i.name === "main");
  const sponsor = images.filter((i) => i.name === "sponsor");
  const media = images.filter((i) => i.name === "media");

  return (
    <Box>
      <ImagesShow images={main} width={"100%"} text={t("generalSponsor")} />
      <ImagesShow images={sponsor} width={"40%"} text={t("sponsor")} />
      <ImagesShow images={media} width={"30%"} text={t("mediaSponsor")} />
    </Box>
  );
};

export default Sponsors;
