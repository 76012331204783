import React, { useState, useEffect, useRef, useContext } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { isNil, omitBy } from "lodash";

import Container from "@mui/material/Container";

import client from "../apis/client";
import Spacer from "../infrastructure/Spacer";
import EductationFilterForm from "../infrastructure/components/EducationFilterForm";
import EducationAccorditionItem from "../infrastructure/components/EducationAccordionItem";
import Spinner from "../infrastructure/components/Spinner";
import { useFormik } from "formik";
import EmptyComponenet from "../infrastructure/components/EmptyComponent";
import { LocalizationContext } from "../context/LocalizationContext";
import { colors } from "../infrastructure/components/resources/colors";
import { Button } from "@mui/material";

const EducationPage = () => {
  const { t } = useContext(LocalizationContext);
  const [lectures, setLectures] = useState();
  const [lecture, setLecture] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const initialValues = {
    leagues: "",
    title: "",
    referee: "",
    observer: "",
    label: "",
  };

  const handleShowForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  const applyFilter = async (values) => {
    try {
      setIsLoading(true);
      let str = "";
      if (values.title !== "" && values.title !== "id")
        str += `title=${values.title}&`;
      if (values.leagues !== "" && values.leagues !== "id")
        str += `league=${values.leagues}&`;
      if (values.referee !== "" && values.referee !== "id")
        str += `referee=${values.referee}&`;
      if (values.observer !== "" && values.observer !== "id")
        str += `observer=${values.observer}&`;
      if (values.label !== "" && values.label !== "id")
        str += `label=${values.label}&`;

      let fil = await client.get(`/education/lectures/?${str.slice(0, -1)}`);

      if (fil.status === 200) {
        setLectures(fil.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: applyFilter,
  });

  let isActive = useRef(true);

  const loadData = async (p) => {
    setIsLoading(true);
    try {
      const params = omitBy({}, isNil);

      const config = { params };

      const { data: responseData } = await client(
        `/education/lectures/`,
        config
      );

      let myData = [];

      if (responseData instanceof Array) {
        myData = responseData.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.created) - new Date(a.created);
        });
      }

      if (isActive.current === true) setLectures(Array.from(myData));

      if (isActive.current === true) {
        let lastId = myData[0].id;

        let lastPost = await client(`/education/lectures/${lastId}`);

        setLecture(await lastPost.data);
      }

      if (isActive.current === true)
        setHasMore(Boolean(responseData.next !== null));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isActive.current === true) {
      loadData();
    }

    return () => {
      isActive.current = false;
    };
  }, []);

  return (
    <Container component={"main"} maxWidth="xl">
      <Spacer height={"4rem"} />
      {!showForm && showForm}
      {!showForm && (
        <div style={{display: 'flex', justifyContent: 'end'}}>
        <Button
          onClick={handleShowForm}
          variant="contained"
          sx={{
            width: {
              xl: "20%",
              lg: "20%",
              md: "20%",
              sm: "50%",
              xs: "50%",
            },
            backgroundColor: colors.red,
            marginTop: "2rem",
          }}
        >
          {t("submitFilter")}
        </Button>
        </div>
      )}
      {showForm && <EductationFilterForm formik={formik} handleCloseForm={handleCloseForm} />}
      <Spacer height={"3rem"} />
      {!isLoading ? (
        <>
          {lectures !== undefined && lectures.length === 0 && (
            <EmptyComponenet text={t("emptyPosts")} />
          )}
          {lectures !== undefined && lectures.length !== 0 && (
            <InfiniteScroll
              hasMore={hasMore}
              dataLength={lectures.length}
              scrollableTarget="scrollableDiv"
              style={{
                overflowX: "hidden",
              }}
              height={"50vh"}
            >
              {lectures.map((item) => {
                return (
                  <div key={item.id}>
                    <EducationAccorditionItem item={item} />
                  </div>
                );
              })}
            </InfiniteScroll>
          )}
        </>
      ) : (
        <Spinner />
      )}
      <Spacer height={"2rem"} />
    </Container>
  );
};

export default EducationPage;
