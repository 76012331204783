import React from "react";

import TextField from "@mui/material/TextField";

const CustomOutlinedTextField = (props) => {
  return (
    <TextField
      name={props.name}
      label={props.label}
      type={props.type || "text"}
      sx={{
        width: props?.width
      }}
      variant="outlined"
      error={
        props.formik?.touched[props.name] &&
        Boolean(props.formik?.errors[props.name])
      }
      helperText={
        props.formik?.touched[props.name] && props.formik?.errors[props.name]
      }
      onChange={(e) => {
        props?.formik?.setFieldValue(props.name, e.target.value);
      }}
      defaultValue={props.formik?.values[props.name]}
    />
  );
};

export default CustomOutlinedTextField;
