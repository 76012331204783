import React, { useContext, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

import CustomTextField from "../../infrastructure/components/CustomTextField";
import { Context as AuthContext } from "../../context/AuthContext";
import { LocalizationContext } from "../../context/LocalizationContext";

import "./styles/SignInPage.css";
import Spacer from "../../infrastructure/Spacer";
import ForgotPassword from "../forms/ForgotPassword";
import { colors } from "../../infrastructure/components/resources/colors";
import CustomModal from "../../infrastructure/components/CustomModal";

const SignInPage = () => {
  const { t } = React.useContext(LocalizationContext);
  const { signin, state } = useContext(AuthContext);
  const [error, setError] = React.useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showForgot, setShowForgot] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleCloseForgot = () => setShowForgot(false);
  const handleShowForgot = () => setShowForgot(true);

  const initialValues = {
    email: localStorage.getItem("email") ?? "",
    password: localStorage.getItem("password") ?? "",
    rememberMe: false,
  };

  React.useEffect(() => {
    const setErrors = () => {
      if (state.loginValues !== undefined) {
        if (state.loginValues.length !== 0 && state.loginKeys.length !== 0) {
          if (
            state.loginValues[0] ===
              "No active account found with the given credentials" &&
            state.loginKeys[0] === "detail"
          ) {
            setError(t("noActiveAccount"));
            return;
          } else {
            setError(t("checkData"));
            return;
          }
        }
      }
    };
    setErrors();
  }, [state, t]);

  const onSignIn = (values) => {
    try {
      const user = {
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
      };

      signin(user);
    } catch (error) {
      setError(t("checkData"));
      return;
    }
  };

  const validationSchema = yup.object({
    email: yup.string().email(t("invalidEmail")).required(t("required")),
    password: yup.string().required(t("required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSignIn,
  });

  return (
      <Container component="main" maxWidth="sm">
        <div xs={12} sm={6} lg={8}>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              margin: "3rem",
              marginTop: "4rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  id="email"
                  name="email"
                  variant="outlined"
                  label={t("email")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  id="password"
                  label={t("password")}
                  name="password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  name="rememberMe"
                  style={{ color: "#777777" }}
                  control={
                    <Checkbox
                      value={formik.values.rememberMe}
                      onChange={formik.handleChange}
                    />
                  }
                  label={t("rememberMe")}
                />
              </Grid>
              {error !== "" && (
                <Grid item xs={12}>
                  <p style={{ color: "red" }}>{error}</p>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  style={{ backgroundColor: colors.red }}
                >
                  {t("submitLogin")}
                </Button>
              </Grid>
              {/* <Grid container justifyContent="flex-end" item>
                <a onClick={handleShowForgot} className="link_text">
                  {t("forgotPassword")}
                </a>
              </Grid>
              <Grid container item>
                <div>
                  {t("dontHaveAccount")}
                  <a href="/register" className="link_text">
                    {t("registerPage")}
                  </a>
                </div>
              </Grid> */}
            </Grid>
          </form>
        </div>
        <CustomModal
          open={showForgot}
          onClose={handleCloseForgot}
          text={t("forgotPassword")}
        >
          <ForgotPassword />
        </CustomModal>
      </Container>
  );
};

export default SignInPage;
