import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

const Timer = (props) => {
  const { initialMinute = 0, initialSeconds = 15, onTimerEnd } = props;
  const [minutes, setMinutes] = useState(
    localStorage.getItem("minutes") ?? initialMinute
  );
  const [seconds, setSeconds] = useState(
    localStorage.getItem("seconds") ?? initialSeconds
  );

  useEffect(() => {
    let intervalId;

    const updateTimer = () => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(intervalId);
        onTimerEnd();
        resetTimer();
      } else {
        if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }
    };

    intervalId = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [minutes, seconds, initialMinute, initialSeconds, onTimerEnd]);

  const resetTimer = () => {
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
  };

  useEffect(() => {
    localStorage.setItem("minutes", minutes);
    localStorage.setItem("seconds", seconds);
  }, [minutes, seconds]);

  useEffect(() => {
    const savedMinutes = localStorage.getItem("minutes");
    const savedSeconds = localStorage.getItem("seconds");

    if (savedMinutes !== null && savedSeconds !== null) {
      setMinutes(parseInt(savedMinutes, 10));
      setSeconds(parseInt(savedSeconds, 10));
    }
  }, []);

  return (
    <>
      {minutes === 0 && seconds === 0 ? null : (
        <Typography textAlign="end" variant="h6">
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds} minuta
        </Typography>
      )}
    </>
  );
};

export default Timer;
