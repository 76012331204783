import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import CustomTextField from "../../infrastructure/components/CustomTextField";
import { LocalizationContext } from "../../context/LocalizationContext";

import { getLeagueName } from "../../infrastructure/components/Leagues";
import history from "../../infrastructure/history";
import client from "../../apis/client";

import "./styles.css";
import { colors } from '../../infrastructure/components/resources/colors';

const initialValues = {
  avatar: "",
  name: "",
  league: 1,
  start_date: moment(new Date()).format("YYYY-MM-DD"),
  end_date: null,
  date_of_birth: moment(new Date()).format("YYYY-MM-DD"),
  gender: "",
  city: "",
  significant_trials: "",
};

const ProfileForm = () => {
  const { t } = React.useContext(LocalizationContext);
  const [leagues, setLeagues] = useState();
  const [eng, setEng] = useState([]);
  const [engError, setEngError] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(initialValues.avatar);
  const [buttonState, setButtonState] = useState(false);
  const inputRef = useRef();

  const handleChangeGender = () => setEng([]);

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      const leaguesData = await client.get("/leagues/");
      if (isActive) {
        setLeagues(
          leaguesData.data.map((option) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    };
    if (isActive) loadData();

    return () => {
      isActive = false;
    };
  }, []);

  const genders = [
    {
      value: 0,
      label: t("male"),
    },
    {
      value: 1,
      label: t("female"),
    },
  ];

  const createEngagementMan = async (l, s, e) => {
    try {
      if (e !== null && e !== "" && e < s) {
        setEngError(t("smallDate"));
      } else if (e === "") {
        if (
          eng.filter(
            (en) =>
              en.league === l && en.start_date === s && en.end_date === null
          ).length > 0
        ) {
          setEngError(t("alreadyExistEng"));
          return;
        } else if (
          (e === null || e === "") &&
          eng.filter((en) => en.end_date === "" || en.end_date === null)
            .length > 0
        ) {
          setEngError(t("noMoreEngMan"));
          return;
        } else {
          setEng([
            ...eng,
            {
              league: l,
              start_date: s,
              end_date: null,
            },
          ]);
          setEngError("");
          return;
        }
      } else if (
        eng.filter(
          (en) =>
            en.league === l &&
            en.start_date === s &&
            en.end_date === (e || "" || null)
        ).length > 0
      ) {
        setEngError(t("alreadyExistEng"));
        return;
      } else if (
        e === null &&
        eng.filter((en) => en.end_date === "" || en.end_date === null).length >
          0
      ) {
        setEngError(t("noMoreEngMan"));
        return;
      } else {
        setEng([
          ...eng,
          {
            league: l,
            start_date: s,
            end_date: e,
          },
        ]);
        setEngError("");
        return;
      }
    } catch (error) {
      alert(t("serverError"));
    }
  };

  const createEngagementWoman = async (l, s, e) => {
    try {
      if (e !== null && e !== "" && e < s) {
        setEngError(t("smallDate"));
      } else if (e === "") {
        if (
          eng.filter(
            (en) =>
              en.league === l && en.start_date === s && en.end_date === null
          ).length > 0
        ) {
          setEngError(t("alreadyExistEng"));
          return;
        } else if (
          (e === null || e === "") &&
          eng.filter((en) => en.end_date === "" || en.end_date === null)
            .length > 1
        ) {
          setEngError(t("noMoreEngWomen"));
          return;
        } else {
          setEng([
            ...eng,
            {
              league: l,
              start_date: s,
              end_date: null,
            },
          ]);
          setEngError("");
          return;
        }
      } else if (
        eng.filter(
          (en) =>
            en.league === l &&
            en.start_date === s &&
            en.end_date === (e || "" || null)
        ).length > 0
      ) {
        setEngError(t("alreadyExistEng"));
        return;
      } else if (
        e === null &&
        eng.filter((en) => en.end_date === "" || en.end_date === null).length >
          1
      ) {
        setEngError(t("noMoreEngWomen"));
        return;
      } else {
        setEng([
          ...eng,
          {
            league: l,
            start_date: s,
            end_date: e,
          },
        ]);
        setEngError("");
        return;
      }
    } catch (error) {
      alert(t("serverError"));
    }
  };

  const deleteEng = (item) => {
    try {
      let array = eng;
      let newArray = [];

      for (let index = 0; index < array.length; index++) {
        newArray.push({ ...array[index], id: index });
      }

      let array1 = newArray.filter((obj) => obj.id !== item);

      setEng(array1);
    } catch (error) {
      alert(t("serverError"));
    }
  };

  const postProfile = async (values) => {
    try {
      setButtonState(true);
      const res = await client.get("/auth/users/me/");
      const own = await res.data.id;

      if (eng.length !== 0) {
        let formData = new FormData();
        if (values?.avatar !== "") {
          formData.append("avatar", values.avatar);
        }
        formData.append("date_of_birth", values.date_of_birth);
        formData.append("gender", values.gender);
        formData.append("city", values.city);
        formData.append("significant_trials", values.significant_trials);
        formData.append("name", values.name);
        formData.append("owner", own);

        for (let i = 0; i < eng.length; i++) {
          formData.append(`engagements[${i}]league`, eng[i].league);
          formData.append(`engagements[${i}]start_date`, eng[i].start_date);
          if (eng[i].end_date !== null && eng[i].end_date !== "") {
            formData.append(`engagements[${i}]end_date`, eng[i].end_date);
          }
        }
        await client.post("/profiles/", formData);

        alert(t("succesProfileCreate"));

        history.push(`/`);
        history.go();
      } else {
        setEngError(t("requiredEngagement"));
        setButtonState(false);
      }
    } catch (err) {
      if (err.response) {
        alert(JSON.stringify(err.response.data));
        setButtonState(false);
      }
    }
  };

  const validationSchema = yup.object({
    avatar: yup.string().required(t("required")),
    name: yup.string().required(t("required")),
    city: yup.string().required(t("required")),
    gender: yup.string().required(t("required")),
    date_of_birth: yup.date().required(t("required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: postProfile,
  });

  return (
    <>
      <Container component="main">
        <form onSubmit={formik.handleSubmit} className="mt-3 m-2">
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img width={"200px"} src={avatarPreview} />
              <Button fullWidth variant="contained" component="label">
                {t("createAvatar")}
                <input
                  name="avatar"
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden
                  ref={inputRef}
                  onChange={(e) => {
                    let files = e.target.files;
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(files[0]);

                    fileReader.onload = (event) => {
                      if (fileReader.readyState === 2) {
                        formik.setFieldValue(
                          "avatar",
                          inputRef.current.files[0]
                        );
                        setAvatarPreview(fileReader.result);
                      }
                    };
                  }}
                />
              </Button>
              {formik.errors.avatar && formik.touched.avatar ? (
                <div
                  className="text-start"
                  style={{ color: "red", fontSize: 11 }}
                >
                  {formik.errors.avatar}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="name"
                name="name"
                variant="outlined"
                label={t("name")}
                InputLabelProps={{ required: true }}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="city"
                name="city"
                variant="outlined"
                label={t("city")}
                value={formik.values.city}
                InputLabelProps={{ required: true }}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="gender"
                type="gender"
                select
                label={t("gender")}
                name="gender"
                InputLabelProps={{ required: true }}
                variant="outlined"
                value={formik.values.gender}
                onChange={(e) => {
                  formik.setFieldValue("gender", e.target.value);
                  handleChangeGender();
                }}
              >
                {genders.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="date_of_birth"
                name="date_of_birth"
                type="date"
                variant="outlined"
                label={t("dateOfBirth")}
                format="yyyy/MM/dd"
                defaultValue={formik.values.date_of_birth}
                selected={formik.values.date_of_birth}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true, required: true }}
                error={
                  formik.touched.date_of_birth &&
                  Boolean(formik.errors.date_of_birth)
                }
                helperText={
                  formik.touched.date_of_birth && formik.errors.date_of_birth
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="significant_trials"
                name="significant_trials"
                multiline
                minRows={4}
                variant="outlined"
                label={t("significantTrials")}
                value={formik.values.significant_trials}
                onChange={formik.handleChange}
                error={
                  formik.touched.significant_trials &&
                  Boolean(formik.errors.significant_trials)
                }
                helperText={
                  formik.touched.significant_trials &&
                  formik.errors.significant_trials
                }
              />
            </Grid>
            {formik.values.gender === 0 && (
              <>
                <Grid item xs={12}>
                  <Typography
                    component="h4"
                    variant="h6"
                    textAlign="center"
                    style={{
                      padding: '2rem',
                      paddingTop: '3rem',
                    }}
                  >
                    {t("engagements")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                    flex: "auto",
                  }}
                >
                  {leagues !== undefined && (
                    <Grid item>
                      <CustomTextField
                        id="league"
                        type="league"
                        select
                        label={t("league")}
                        name="league"
                        variant="outlined"
                        value={formik.values.league}
                        onChange={formik.handleChange}
                      >
                        {leagues.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </Grid>
                  )}
                  <Grid item>
                    <CustomTextField
                      id="start_date"
                      name="start_date"
                      type="date"
                      variant="outlined"
                      label={t("startDate")}
                      format="yyyy/MM/dd"
                      defaultValue={formik.values.start_date}
                      selected={formik.values.start_date}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                      error={
                        formik.touched.start_date &&
                        Boolean(formik.errors.start_date)
                      }
                      helperText={
                        formik.touched.start_date && formik.errors.start_date
                      }
                    />
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      id="end_date"
                      name="end_date"
                      type="date"
                      variant="outlined"
                      label={t("endDate")}
                      format="yyyy/MM/dd"
                      selected={formik.values.end_date}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                      error={
                        formik.touched.end_date &&
                        Boolean(formik.errors.end_date)
                      }
                      helperText={
                        formik.touched.end_date && formik.errors.end_date
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        createEngagementMan(
                          formik.values.league,
                          formik.values.start_date,
                          formik.values.end_date
                        )
                      }
                      style={{ backgroundColor: colors.red, padding: 3 }}
                    >
                      {t("addEngagement")}
                    </Button>
                  </Grid>{" "}
                </Grid>{" "}
                <Grid>
                  {engError ? <p style={{ color: "red" }}>{engError}</p> : null}
                </Grid>
                <Grid item xs={12}>
                  {eng !== undefined && eng.length !== 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <table id="table_style">
                            <thead>
                              <tr key={"header"}>
                                <th>{t("league")}</th>
                                <th>{t("startDate")}</th>
                                <th>{t("endDate")}</th>
                                <th>{""}</th>
                              </tr>
                            </thead>
                            {eng.map((option, index) => (
                              <tbody key={index}>
                                <tr key={index}>
                                  <td>
                                    {getLeagueName(leagues, option.league)}
                                  </td>
                                  <td>
                                    {moment(option.start_date).format(
                                      "DD. MM. YYYY."
                                    )}
                                  </td>
                                  <td>
                                    {option.end_date === null
                                      ? "trenutno"
                                      : moment(option.end_date).format(
                                          "DD. MM. YYYY."
                                        )}
                                  </td>
                                  <td>
                                    <Button
                                      style={{ backgroundColor: "transparent" }}
                                      onClick={() => {
                                        deleteEng(index);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="icon"
                                        icon={faTrashAlt}
                                        size="1x"
                                      ></FontAwesomeIcon>
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            {formik.values.gender === 1 && (
              <>
                <Grid item xs={12}>
                  <Typography
                    component="h4"
                    variant="h6"
                    textAlign="center"
                    style={{
                      padding: '2rem',
                      paddingTop: '3rem',
                    }}
                  >
                    {t("engagements")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                    flex: "auto",
                  }}
                >
                  {leagues !== undefined && (
                    <Grid item>
                      <CustomTextField
                        id="league"
                        type="league"
                        select
                        label={t("league")}
                        name="league"
                        variant="outlined"
                        value={formik.values.league}
                        onChange={formik.handleChange}
                      >
                        {leagues.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </Grid>
                  )}
                  <Grid item>
                    <CustomTextField
                      id="start_date"
                      name="start_date"
                      type="date"
                      variant="outlined"
                      label={t("startDate")}
                      format="yyyy/MM/dd"
                      defaultValue={formik.values.start_date}
                      selected={formik.values.start_date}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                      error={
                        formik.touched.start_date &&
                        Boolean(formik.errors.start_date)
                      }
                      helperText={
                        formik.touched.start_date && formik.errors.start_date
                      }
                    />
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      id="end_date"
                      name="end_date"
                      type="date"
                      variant="outlined"
                      label={t("endDate")}
                      format="yyyy/MM/dd"
                      selected={formik.values.end_date}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                      error={
                        formik.touched.end_date &&
                        Boolean(formik.errors.end_date)
                      }
                      helperText={
                        formik.touched.end_date && formik.errors.end_date
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        createEngagementWoman(
                          formik.values.league,
                          formik.values.start_date,
                          formik.values.end_date
                        )
                      }
                      style={{ backgroundColor: colors.red, padding: 3 }}
                    >
                      {t("addEngagement")}
                    </Button>
                  </Grid>{" "}
                </Grid>{" "}
                <Grid>
                  {engError ? <p style={{ color: "red" }}>{engError}</p> : null}
                </Grid>
                <Grid item xs={12}>
                  {eng !== undefined && eng.length !== 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <table id="table_style">
                            <thead>
                              <tr key={"header"}>
                                <th>{t("league")}</th>
                                <th>{t("startDate")}</th>
                                <th>{t("endDate")}</th>
                                <th>{""}</th>
                              </tr>
                            </thead>
                            {eng.map((option, index) => (
                              <tbody key={index}>
                                <tr key={index}>
                                  <td>
                                    {getLeagueName(leagues, option.league)}
                                  </td>
                                  <td>
                                    {moment(option.start_date).format(
                                      "DD. MM. YYYY."
                                    )}
                                  </td>
                                  <td>
                                    {option.end_date === null
                                      ? "trenutno"
                                      : moment(option.end_date).format(
                                          "DD. MM. YYYY."
                                        )}
                                  </td>
                                  <td>
                                    <Button
                                      style={{ backgroundColor: "transparent" }}
                                      onClick={() => {
                                        deleteEng(index);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="icon"
                                        icon={faTrashAlt}
                                        size="1x"
                                      ></FontAwesomeIcon>
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={buttonState}
              style={{ backgroundColor: colors.red }}
            >
              {t("submitProfile")}
            </Button>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default ProfileForm;
